/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Card,
  Avatar,
} from '@mui/material';
import getWindowSize from '../../utils/getScreenSize';
import { responsiveFontSizes } from '../../utils/getFontValue';
import KeyHole from '../../assets/images/KeyHole.png';

// TODO: Check if this is needed anymore3
import '../EnquantaAdvantage/EnquantaAdvantage.css';
import SecureVaultImage from '../../assets/images/QuantaSafe.png';
import ArrowImage from '../../assets/images/ArrowWithText.png';

import Iconify from '../Iconify';

const featuresList = [{
  icon: 'carbon:ibm-watsonx-code-assistant-for-z-refactor',
  title: 'Nation-State Actors',
  description: 'Today’s adversaries equipped with dark web tools, global network access, and AI',
},
{
  icon: 'carbon:block-storage',
  title: 'Ransomware',
  customSize: 58,
  description: 'Nearly 2,000 compliant systems were breached last year in the US, impacting more than 500 million people',
}, {
  icon: 'carbon:wheat',
  title: 'Harvest Now Tactics',
  description: 'Harvest Now - Decrypt Later tactics that steal encrypted data now to be quickly decrypted when CRQC becomes mainstream',
}, {
  icon: 'carbon:unknown',
  title: 'CRQC with AGI',
  description: 'Future unrecognized unknowns Including the combination of CRQC with AGI',
},
];

function SecureVaultFeatures({ windowSize }) {
  let boxHeight = '1';
  if (windowSize.innerWidth >= 1200) {
    boxHeight = '288px';
  } else if (windowSize.innerWidth >= 900) {
    boxHeight = '300px';
  } else if (windowSize.innerWidth >= 800) {
    boxHeight = '330px';
  } else if (windowSize.innerWidth >= 700) {
    boxHeight = '350px';
  } else if (windowSize.innerWidth >= 600) {
    boxHeight = '390px';
  }
  return (
    <Grid
      sx={{ px: { xs: 3, md: 3, lg: 3 } }}
      container
      rowSpacing={6}
      columnSpacing={8}
      alignItems="center"
      justify="center"
    >
      {
        featuresList.map((item, i) => (
          <Grid item xs={12} sm={6} md={6} key={item.title}>
            <Box
              component={Card}
              padding={4}
              borderRadius={2}
              height={boxHeight || 1}
              width={1}
            // data-aos="fade-up"
            // data-aos-delay={i * 100}
            >
              <Box display="flex" flexDirection="column" sx={{ alignItems: 'center' }}>
                <Box
                  component={Avatar}
                  width={90}
                  height={90}
                  marginBottom={2}
                  sx={{ backgroundColor: '#009DDC' }}
                >
                  <Iconify icon={item.icon} sx={{ width: item.customSize || 70, height: item.customSize || 70, color: '#EEF1EF' }} />
                </Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    ...responsiveFontSizes({
                      xs: 20, sm: 22, md: 24, lg: 26,
                    }),
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  sx={{
                    ...responsiveFontSizes({
                      xs: 16, sm: 16, md: 16, lg: 16,
                    }),
                    textAlign: 'center',
                  }}
                >
                  {item.description}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))
      }
    </Grid>
  );
}

function SecureVaultIntro({ windowSize }) {
  let logoHeight = '250px';
  if (windowSize.innerWidth >= 1200) {
    logoHeight = '500px';
  } else if (windowSize.innerWidth >= 900) {
    logoHeight = '400px';
  } else if (windowSize.innerWidth >= 600) {
    logoHeight = '300px';
  }
  return (
    <Grid
      container
      spacing={4}
      sx={{
        backgroundColor: '#DEDBE9',
        mb: 4,
      }}
    >
      <Grid
        item
        container
        xs={12}
        md={6}
        alignItems="center"
        justifyContent={{ xs: 'center', md: 'end' }}
        order={{ xs: 1, md: 0 }}
      >
        <Box
          // data-aos="fade-right"
          sx={{
            mr: { xs: 0, md: 8 },
          }}
        >
          <Box
            marginBottom={2}
          >
            <Typography
              variant="h1"
              sx={{
                ...responsiveFontSizes({
                  xs: 75, sm: 75, md: 65, lg: 100,
                }),
                color: '#7F5CAD',
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              En
              <span style={{ color: '#054A91' }}>Quanta</span>
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography
              variant="h6"
              component="p"
              sx={{
                ...responsiveFontSizes({
                  xs: 28, sm: 34, md: 32, lg: 36,
                }),
                textAlign: { xs: 'center', md: 'left' },
                paddingLeft: { md: '2px', lg: '4px' },
                // color: '#AE99DC',
              }}
              color="text.secondary"
            >
              Neutralize Every Threat
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        justifyContent={{ xs: 'center', md: 'start' }}
        xs={12}
        md={6}
        order={{ xs: 0, md: 1 }}
      >
        <Box
          component="img"
          loading="lazy"
          // data-aos="fade-left"
          // height={1}
          // width={1}
          src={SecureVaultImage}
          alt="..."
          height={logoHeight}
          sx={{
            objectFit: 'contain',
          }}
        />
      </Grid>
    </Grid>
  );
}

export default function WhatIsQuantaSafeLanding() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  let arrowImageWidth = '85%';
  if (windowSize.innerWidth >= 1200) {
    arrowImageWidth = '921px';
  } else if (windowSize.innerWidth >= 900) {
    arrowImageWidth = '750px';
  } else if (windowSize.innerWidth >= 600) {
    arrowImageWidth = '80%';
  }

  return (
    <Box sx={{
      height: 'auto',
      backgroundColor: '#EEF1EF',
      display: 'flex',
      marginTop: '35px',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
    >
      <Grid
        container
        columnSpacing={{
          xs: 0, sm: 0, md: 0, lg: 6,
        }}
        rowSpacing={{
          xs: 2, sm: 0, md: 0, lg: 3,
        }}
        sx={{ justifyContent: 'center' }}
      >
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: '#DEDBE9',
            pl: 0,
          }}
        >
          <SecureVaultIntro windowSize={windowSize} />
          <Box
            component="svg"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: '100%',
              marginBottom: '-10px',
              mt: 8,
            }}
          >
            <path
              fill="#EEF1EF"
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ paddingLeft: '0px' }}
        >
          <Typography
            variant="h3"
            sx={{
              marginBottom: '5px',
              ...responsiveFontSizes({
                xs: 28, sm: 28, md: 28, lg: 36,
              }),
              color: '#054A91',
              fontWeight: 'bold',
              textAlign: 'center',
              pl: { lg: 40 },
              pr: { lg: 40 },
              mb: 2,
              mt: 0,
            }}
          >
            Quantum Cyber Resilience
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              py: 2,
              my: { xs: 0, md: 4, lg: 4 },
            }}
          >
            <img src={ArrowImage} alt="QuantaSafe" style={{ width: arrowImageWidth }} />
          </Box>
          <Container
            sx={{
              px: { xs: 3, md: 5, lg: 4 },
              my: { xs: 3, md: 4, lg: 5 },
            }}
          >
            <Typography
              sx={{
                marginTop: '30px',
                marginBottom: '20px',
                ...responsiveFontSizes({
                  xs: 18, sm: 20, md: 20, lg: 22,
                }),
              }}
            >
              EnQuanta™ is a suite of cutting-edge software products built on EnQuanta&apos;s
              patent-pending
              {' '}
              <span style={{ fontWeight: 'bold' }}>quantum cyber resilience</span>
              {' '}
              solution. It can be seamlessly incorporated into software
              or firmware systems to
              {' '}
              <span style={{ fontWeight: 'bold' }}>protect against</span>
              {' '}
              both current and future
              {' '}
              <span style={{ fontWeight: 'bold' }}>cyber threats</span>
              {', '}
              including those posed by quantum computing and artificial intelligence. EnQuanta delivers unparalleled quantum cyber
              resilience solutions to protect your digital assets, whether in transit or at rest.
              Our QuantaPack™ process uses
              {' '}
              <span style={{ fontWeight: 'bold' }}>quantum hardening techniques</span>
              {' '}
              on your data to create a paired QuantaKey™ and QuantaSafe™ bundle. This advanced technology protects your data
              from current and future threats, ensuring security from malicious actors.
            </Typography>
          </Container>
          <Box
            component="svg"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: '100%',
              marginBottom: '-11px',
              mt: 8,
            }}
          >
            <path
              fill="#B1DAEC"
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            />
          </Box>

        </Grid>
        <Grid
          item
          xs={12}
          style={{ paddingLeft: '0px' }}
          sx={{
            backgroundColor: '#B1DAEC',
          }}
        >
          <Container sx={{ mb: 5 }}>
            <Typography
              variant="h3"
              sx={{
                marginBottom: '5px',
                ...responsiveFontSizes({
                  xs: 28, sm: 28, md: 28, lg: 36,
                }),
                color: '#054A91',
                fontWeight: 'bold',
                textAlign: 'center',
                mb: 8,
                // px: { xs: 3, md: 5, lg: 4 },
              }}
            >
              Protect your Digital Assets today from:
            </Typography>
            <SecureVaultFeatures windowSize={windowSize} />
          </Container>

          <Box
            component="svg"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: '100%',
              marginBottom: '-10px',
              mt: 12,
            }}
          >
            <path
              fill="#EEF1EF"
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
