/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
// @mui
import {
  Typography, Container, Stack, Avatar, Link,
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { browserName } from 'react-device-detect';
import Image from '../Image';
import getWindowSize, { breakpoints } from '../../utils/getScreenSize';

// ----------------------------------------------------------------------

export default function FeaturedBlog({ post }) {
  const {
    title, duration, coverImg, coverImgSm, description, author,
    internalLink,
  } = post;
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const navigate = useNavigate();

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Container sx={{
      '&.MuiContainer-root': {
        paddingLeft: { xs: 2.5, md: 0 },
        paddingRight: { xs: 2.5, sm: 0 },
        pt: { xs: 8, lg: 2 },
      },
    }}
    >
      <Stack direction={{ xs: 'column', md: 'row' }} onClick={() => { navigate(internalLink); }}>
        <Image
          src={windowSize.innerWidth < breakpoints.sm ? coverImgSm : coverImg}
          alt={title}
          sx={{ flexGrow: 1, height: 560, borderRadius: 2 }}
        />

        <Stack
          spacing={1}
          sx={{
            mx: { xs: 0, md: 'auto' },
            pl: { md: 4 },
            py: { xs: 3, md: 5 },
            maxWidth: { md: 408 },
            minWidth: browserName === 'Safari' ? { md: 290 } : 'none',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{ typography: 'caption', color: 'text.disabled' }}
          >
            {duration}
          </Stack>

          <Link
            component={RouterLink}
            to="/blog/myFaceAndVoiceIdentifyMe"
            variant="h3"
            sx={{ lineHeight: 1, color: '#054A91' }}

          >
            {title}
          </Link>

          <Typography sx={{ color: 'text.secondary', flexGrow: 1 }}>{description}</Typography>

          <Stack direction="row" alignItems="center" sx={{ pt: 1.5, typography: 'body2' }}>
            <Avatar src={author.picture} sx={{ mr: 1 }} />
            {author.name}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}
