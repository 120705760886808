/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box, Button, Card, CardActions, Container, Snackbar, SnackbarContent, Stack, Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import SecureVaultImage from '../../assets/images/SecureVault.png';
import SecureStorageImage from '../../assets/images/SecureStorage.png';
import SecureTransferImage from '../../assets/images/SecureTransfer.png';
import SecureCommsImage from '../../assets/images/SecureComms.png';
import SecureLiteImage from '../../assets/images/SecureLite.png';
import SecureDatabaseImage from '../../assets/images/SecureDatabase.png';
import SecureAgentImage from '../../assets/images/SecureAgent.png';

import { responsiveFontSizes } from '../../utils/getFontValue';
import getWindowSize from '../../utils/getScreenSize';

export const pageHeader = 'QuantaSafe Documentation';

function DocsColorCard({
  title, text, imageSrc, bColor, handleClick, fColor, width,
}) {
  let smallScreenException = '230px';
  if (width < 640) {
    smallScreenException = '315px';
  } else if (width < 680) {
    smallScreenException = '290px';
  } else if (width < 720) {
    smallScreenException = '270px';
  } else if (width < 760) {
    smallScreenException = '230px';
  } else if (width < 800) {
    smallScreenException = '200px';
  } else if (width < 900) {
    smallScreenException = '170px';
  }
  return (
    <Card
      sx={{
        py: 2,
        pt: 6,
        px: 3,
        color: bColor,
        bgcolor: '#EEF1EF',
        borderColor: bColor,
        borderWidth: '9px',
        borderStyle: 'solid',
        boxShadow: `-8px 12px 32px 0px ${alpha(bColor, 0.2)}`,
        textAlign: 'center',
      }}
    >
      {/* <Image alt={icon} src={icon} sx={{ width: 88, height: 88, mx: 'auto' }} /> */}
      <Box sx={
        {
          display: 'flex',
          justifyContent: 'center',
        }
      }
      >
        <img
          style={{
            height: '75px',
          }}
          src={imageSrc}
          alt="cyberSecurity"
        />
      </Box>
      <Stack spacing={2} sx={{ my: 3 }}>
        <Typography
          variant="h6"
          sx={{
            ...responsiveFontSizes({
              xs: 24, sm: 28, md: 32, lg: 32,
            }),
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: fColor,
            ...responsiveFontSizes({
              xs: 17, sm: 17, md: 17, lg: 17,
            }),
            height: { sm: smallScreenException, md: '250px' },
          }}
        >
          {text}
        </Typography>
      </Stack>
      <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size="small"
          sx={{
            color: fColor,
            ...responsiveFontSizes({
              xs: 15, sm: 15, md: 15, lg: 15,
            }),
          }}
          onClick={handleClick}
        >
          Go to Docs

        </Button>
      </CardActions>
    </Card>
  );
}

export default function Documentation() {
  //   const isDesktop = useResponsive('up', 'md');
  //   const isLarge = useResponsive('up', 'lg');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return (
    <Box sx={{
      height: 'auto',
      minHeight: { md: '100vh' },
      '@media (min-width: 600px)': {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      marginTop: '64px',
      backgroundColor: '#EEF1EF',
      display: 'flex',
      flexDirection: 'column',
      //   justifyContent: 'center',
    }}
    >
      <Container sx={{
        py: { xs: 3, md: 3, lg: 3 },
      }}
      >
        <Stack
          sx={{
            maxWidth: 480,
            mb: { xs: 5, md: 2 },
            mx: { xs: 'auto', md: 'unset' },
            textAlign: { xs: 'center', md: 'unset' },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mt: 2,
              mb: 3,
              ...responsiveFontSizes({
                xs: 32, sm: 42, md: 42, lg: 42,
              }),
              color: '#054A91',
            }}
          >
            {pageHeader}
          </Typography>

        </Stack>

        <Box
          sx={{
            alignItems: 'center',
            display: 'grid',
            gap: 4,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(3, 1fr)',
            },
          }}
        >
          <DocsColorCard
            title="Secure Vault"
            text={`QuantaSafe Secure Vault uses quantum entropy and Quantum Hybrid
            Ciphers to encrypt data in non-volatile memory and drives, ensuring security
            with 'render useless' tactics and Software Anti-Tamper for volatile memory.`}
            imageSrc={SecureVaultImage}
            bColor="#7F5CAD"
            fColor="#422A76"
            width={windowSize.innerWidth}
            handleClick={() => setOpenSnackBar(true)}
          />
          <DocsColorCard
            title="Secure Storage"
            text={`QuantaSafe Secure Storage combats ransomware with secure network
            storage and fortified communications, using a RAID configuration and
            QCU/QuID pairs across nodes to maintain functionality even if 40% of servers are compromised.`}
            imageSrc={SecureStorageImage}
            bColor="#7F5CAD"
            fColor="#422A76"
            width={windowSize.innerWidth}
            handleClick={() => setOpenSnackBar(true)}
          />
          <DocsColorCard
            title="Secure Transfer"
            text={`QuantaSafe Secure Transfer uses a PuP system with Quantum Symmetric
            Secrets for secure Point-to-Point/Multipoint file sharing over 'Black Networks',
            rendering data indecipherable to unauthorized interceptors.`}
            imageSrc={SecureTransferImage}
            bColor="#7F5CAD"
            fColor="#422A76"
            width={windowSize.innerWidth}
            handleClick={() => setOpenSnackBar(true)}
          />
          <DocsColorCard
            title="Secure Comms"
            text={`QuantaSafe Secure Comms uses a PuP system with Quantum Symmetric Secrets
            for low-latency, secure messaging over 'Black Networks', ensuring data remains indecipherable to unauthorized interceptors.`}
            imageSrc={SecureCommsImage}
            bColor="#7F5CAD"
            fColor="#422A76"
            width={windowSize.innerWidth}
            handleClick={() => setOpenSnackBar(true)}
          />
          <DocsColorCard
            title="Secure Lite"
            text={`QuantaSafe Secure Lite features a Core CLI for easy integration into diverse workflows,
            offering standalone, network-independent packaging and reconstituting of data with flexible data management options.`}
            imageSrc={SecureLiteImage}
            bColor="#7F5CAD"
            fColor="#422A76"
            width={windowSize.innerWidth}
            handleClick={() => setOpenSnackBar(true)}
          />
          <DocsColorCard
            title="Secure Database"
            text={`QuantaSafe Secure Database offers dynamic data management with continuous,
            selective Packager CRUD operations, using unique GUIDs for precise tracking, retrieval, and efficient, secure handling of evolving datasets.`}
            imageSrc={SecureDatabaseImage}
            bColor="#7F5CAD"
            fColor="#422A76"
            width={windowSize.innerWidth}
            handleClick={() => setOpenSnackBar(true)}
          />
          <DocsColorCard
            title="Secure Agent"
            text={`QuantaSafe Secure Agent remotely executes commands on devices, adjusting parameters
            like video feeds and distributing software updates securely, with anti-tamper features and control over hardware, including vehicles, ensuring safety and integrity.`}
            imageSrc={SecureAgentImage}
            bColor="#7F5CAD"
            fColor="#422A76"
            width={windowSize.innerWidth}
            handleClick={() => setOpenSnackBar(true)}
          />
        </Box>
      </Container>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => {
          setOpenSnackBar(false);
        }}
        message="QuantaSafe Docs Coming Soon!"
      >
        <SnackbarContent
          style={{
            backgroundColor: '#F17300',
            boxShadow: 'none',
            color: '#EEF1EF',
          }}
          message="QuantaSafe Docs Coming Soon!"
        />
      </Snackbar>
    </Box>
  );
}
