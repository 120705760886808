/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Link,
} from '@mui/material';
import FaaShutdownCoverImage from '../../../assets/images/jan14.jpg';

import { responsiveFontSizes } from '../../../utils/getFontValue';

export default function Jan14() {
  return (
    <>
      <Box sx={{
        backgroundImage: `url(${FaaShutdownCoverImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
        marginTop: '64px',
      }}
      >
        <Container sx={{
          width: '100vw',
          height: '66vw',
          // height: {
          //   xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          // },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#EEF1EF',
        }}
        />
      </Box>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#054A91',
                  ...responsiveFontSizes({
                    xs: 34, sm: 34, md: 34, lg: 40,
                  }),
                }}
              >
                Quantum Threats, Cybersecurity Upheaval
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                January 14th, 2024
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                This week we explore the latest developments in quantum encryption,
                the evolving landscape of ransomware in 2023, new cybersecurity requirements
                for hospitals under the Biden administration, and a remarkable account of how
                Stuxnet, a billion-dollar malware, infiltrated an Iranian nuclear facility.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Quantum Encryption Under Kyberslash Threat
              </Typography>
              <Typography>
                A new wave of cyberattacks, known as Kyberslash, is posing significant risks
                to quantum encryption projects. These attacks exploit vulnerabilities in
                quantum encryption systems, challenging their presumed impenetrability.
                This development raises crucial questions about the future of secure
                data transmission in the quantum era.
                {' '}
                <Link
                  href="https://www.bleepingcomputer.com/news/security/kyberslash-attacks-put-quantum-encryption-projects-at-risk/"
                  target="_blank"
                  rel="noopener"
                >
                  [BleepingComputer]
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                The State Of Ransomware In 2023
              </Typography>
              <Typography>
                As ransomware continues to evolve, a new report from BlackFog sheds light on the
                current state of these malicious attacks. The report reveals trends, tactics,
                and the impact of ransomware on businesses and individuals in 2023.
                Understanding these dynamics is essential for developing effective
                defense strategies.
                {' '}
                <Link
                  href="https://www.blackfog.com/the-state-of-ransomware-in-2023/"
                  target="_blank"
                  rel="noopener"
                >
                  [BlackFog]
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                New Hospital Cybersecurity Mandates
              </Typography>
              <Typography>
                The Biden administration is introducing stringent cybersecurity standards
                for hospitals, a significant move aimed at bolstering the security of
                healthcare systems. These new requirements are designed to ensure the
                implementation of robust security measures to protect patient data and
                critical hospital infrastructure from the rising threats of cyberattacks.
                Hospitals will need to comply with these standards to enhance their defense
                mechanisms against potential cybersecurity incidents.
                {' '}
                <Link
                  href="https://www.techradar.com/pro/cyberattacks-are-having-fatal-consequences-so-the-white-house-wants-hospital-funding-tied-to-hospital-security"
                  target="_blank"
                  rel="noopener"
                >
                  [TechRadar]
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Stuxnet Infiltration Tactics Revealed
              </Typography>

              <Typography>
                A recent report reveals a Dutch engineer&apos;s ingenuity in introducing
                the Stuxnet malware into an Iranian nuclear facility.
                This billion-dollar cyberweapon was delivered through a compromised water pump,
                demonstrating a unique approach to cyber warfare.
                The Stuxnet operation has become a benchmark in the history of
                cybersecurity and state-sponsored cyberattacks.
                {' '}
                <Link
                  href="https://www.securityweek.com/dutch-engineer-used-water-pump-to-get-billion-dollar-stuxnet-malware-into-iranian-nuclear-facility-report/"
                  target="_blank"
                  rel="noopener"
                >
                  [SecurityWeek]
                </Link>
              </Typography>
            </Grid>

          </Grid>
        </Container>
      </Box>

    </>
  );
}
