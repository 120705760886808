/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
import { Stack } from '@mui/material';
import React from 'react';
// ----------------------------------------------------------------------

export default function TextIconLabel({
  icon, value, endIcon = false, sx, ...other
}) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        ...sx,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {!endIcon && icon}
      {value}
      {endIcon && icon}
    </Stack>
  );
}
