/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
} from '@mui/material';
import getWindowSize from '../../utils/getScreenSize';
import './animation.css';
import { responsiveFontSizes } from '../../utils/getFontValue';
import KeyHole from '../../assets/images/KeyHole.png';

// TODO: Check if this is needed anymore3
import '../EnquantaAdvantage/EnquantaAdvantage.css';
import SecureVaultFeatures from './SecureVaultFeatures';
import SecureVaultUseCases from './SecureVaultUseCases';
import SecureVaultIntro from './SecureVaultIntro';

export default function SecureVault() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  let quantumIntelligenceDiagramHeight = '250px';
  if (windowSize.innerWidth >= 1200) {
    quantumIntelligenceDiagramHeight = '370px';
  } else if (windowSize.innerWidth >= 900) {
    quantumIntelligenceDiagramHeight = '320px';
  } else if (windowSize.innerWidth >= 600) {
    quantumIntelligenceDiagramHeight = '300px';
  }
  return (
    <Box sx={{
      height: 'auto',
      // minHeight: { md: '100vh' },
      '@media (min-width: 600px)': {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
      backgroundColor: '#EEF1EF',
      display: 'flex',
      marginTop: '80px',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
    >
      <Grid
        container
        columnSpacing={{
          xs: 0, sm: 0, md: 0, lg: 6,
        }}
        rowSpacing={{
          xs: 2, sm: 0, md: 0, lg: 3,
        }}
        sx={{ justifyContent: 'center' }}
      >
        <Grid
          item
          md={12}
          sx={{
            backgroundColor: '#DEDBE9',
            pl: 0,
          }}
          style={{ paddingLeft: '0px' }}
        >
          <SecureVaultIntro />
          <Box sx={{
            height: '500px',
            width: '580px',
            ml: '100px',
            borderRadius: '40px',
            background: 'linear-gradient(90deg, #AE99DC, #7F5CAD, #422A76, #054A91)',
            backgroundSize: '400% 400%',
            animationName: 'example',
            animationDuration: '4s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'ease',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          >
            <Box
              component="img"
              loading="lazy"
              height={1}
              width={1}
              src={KeyHole}
              alt="..."
              maxWidth={230}
              maxHeight={230}
              sx={{
                objectFit: 'contain',
              }}
            />
          </Box>
          <Box
            component="svg"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: '100%',
              marginBottom: '-10px',
              mt: 8,
            }}
          >
            <path
              fill="#EEF1EF"
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            />
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          style={{ paddingLeft: '0px' }}
        >
          <Typography
            variant="h3"
            sx={{
              marginBottom: '5px',
              ...responsiveFontSizes({
                xs: 22, sm: 28, md: 28, lg: 36,
              }),
              color: '#054A91',
              fontWeight: 'bold',
              textAlign: 'center',
              pl: { lg: 40 },
              pr: { lg: 40 },
              mb: 2,
              mt: 0,
            }}
          >
            What Is Secure Vault?
          </Typography>
          <Container>
            <Typography
              sx={{
                marginBottom: '5px',
                // ...responsiveFontSizes({
                //   xs: 22, sm: 28, md: 28, lg: 36,
                // }),
              }}
            >
              QuantaSafe Secure Vault is an advanced software storage solution with quantum resilience protection for data-at-rest. It ensures the protection of digital assets, including firmware, datasets, and critical program information (CPI) by leveraging the power of Quantum Random Number Generators (QRNG) and proprietary Quantum Hybrid Cipher (QHC) stacks. Secure Vault secures digital assets by incrypting a unique protected pair of packages containing an amalgamated codex, called a Quantum Cryptex Unit (QCU), and its corresponding Quantum Incrypted Data (QuID) bundle. Our terminology “incrypt” and “dicrypt” is analogous to “encrypt” and “decrypt”, but encompasses our expanded cryptographic agile process. This technology is particularly effective against data theft, tampering, and reverse engineering, by storing sensitive digital assets in an impenetrable incrypted state. This guarantees the security and integrity of the protected data even if the system is compromised.
            </Typography>
          </Container>
          <Box
            component="svg"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: '100%',
              marginBottom: '-10px',
              mt: 8,
            }}
          >
            <path
              fill="#B1DAEC"
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            />
          </Box>

        </Grid>
        <Grid
          item
          md={12}
          style={{ paddingLeft: '0px' }}
          sx={{
            backgroundColor: '#B1DAEC',
          }}
        >
          <Container>
            <Typography
              variant="h3"
              sx={{
                marginBottom: '5px',
                ...responsiveFontSizes({
                  xs: 22, sm: 28, md: 28, lg: 36,
                }),
                color: '#054A91',
                fontWeight: 'bold',
                textAlign: 'center',
                mb: 4,
              }}
            >
              Features
            </Typography>
            <SecureVaultFeatures />
          </Container>

          <Box
            component="svg"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: '100%',
              marginBottom: '-10px',
              mt: 12,
            }}
          >
            <path
              fill="#EEF1EF"
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            />
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          style={{ paddingLeft: '320px' }}
          sx={{
            backgroundColor: '#EEF1EF',
            pl: { lg: 40 },
            pr: { lg: 40 },
            pb: 5,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              marginBottom: '5px',
              ...responsiveFontSizes({
                xs: 22, sm: 28, md: 28, lg: 36,
              }),
              color: '#054A91',
              fontWeight: 'bold',
              textAlign: 'center',
              mb: 4,
            }}
          >
            Use Cases
          </Typography>
          <SecureVaultUseCases />
        </Grid>
      </Grid>
    </Box>
  );
}
