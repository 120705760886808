/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
} from '@mui/material';
import FaaShutdownCoverImage from '../../../assets/images/CoverImages/FaaShutDownCoverImage.jpg';

import { responsiveFontSizes } from '../../../utils/getFontValue';

export default function FaaShutdown() {
  return (
    <>
      <Box sx={{
        backgroundImage: `url(${FaaShutdownCoverImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
        marginTop: '64px',
      }}
      >
        <Container sx={{
          width: '100vw',
          height: '66vw',
          // height: {
          //   xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          // },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#EEF1EF',
        }}
        />
      </Box>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#054A91',
                  ...responsiveFontSizes({
                    xs: 34, sm: 34, md: 34, lg: 40,
                  }),
                }}
              >
                FAA Shutdown
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Cyber Resilience and the Risks to Financial Services,
                Healthcare, and Other Industries
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                On January 11th,
                the Federal Aviation Administration (FAA) experienced a widespread shutdown
                that lasted for several hours and affected flights and travelers nationwide.
                It is believed that the outage may have been caused by a corrupted file within
                the Notice to Air Missions (NOTAM) system. An investigation will ensue to find
                out if a cyber attack may have been involved. Regardless, it is clear
                that every business needs to have a Cyber Resilience strategy in place.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                What is Cyber Resilience?
              </Typography>
              <Typography>
                Cyber Resilience is a set of strategies and best practices designed to ensure
                organizations remain secure and functional even in cases of disruption
                or cyber attack. This type of approach considers potential threats in advance
                and develops plans to mitigate them, allowing unanticipated operations
                to be addressed quickly and effectively.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Mission Continuity: Prepare for the worst and
                {' '}
                <strike>hope for the best</strike>
                {' '}
                keep moving forward
              </Typography>
              <Typography>
                To help prepare infrastructure,
                organizations should conduct extensive Business Continuity
                and Disaster Recovery Exercises to practice responding to
                outages within specific time frames.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                Examples of Business Continuity and Disaster Recovery Exercises
                are power outages, natural disasters, data breaches,
                system failure or other scenarios that could disrupt operations.
                Through these activities,
                organizations can review their business continuity plans,
                determine gaps in their strategies and improve their overall resilience.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                Luckily, the FAA ordered a ground stop nationwide
                before anything serious happened.
                However, other industries may not have that same luxury of control.
                Financial services, healthcare, energy, government, defense,
                intelligence, and many other industries could experience catastrophic
                results with far-reaching impacts given a similar sudden disruption.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Who needs Cyber Resilience? Everyone
              </Typography>

              <Typography>
                In light of this latest FAA shutdown, it is clear that investing in
                Cyber Resilience is essential when it comes to protecting data,
                reducing risks, and sustaining operations even during unexpected events.
                Implementing robust Cyber Resilience helps organizations stay one
                step ahead when it comes to cybersecurity measures while also giving
                them peace of mind knowing their systems will continue
                the mission forward, no matter what.
              </Typography>
            </Grid>

          </Grid>
        </Container>
      </Box>

    </>
  );
}
