/* eslint-disable*/
import React from 'react';
// @mui
import { Stack } from '@mui/material';
// hooks
import { useResponsive } from '../../hooks';
// components
import SearchInput from '../SearchInput';
//
// import BlogSidebarAuthor from './BlogSidebarAuthor';
import NewsSidebarPopularTags from './NewsSidebarPopularTags';
// import BlogSidebarRecentPosts from './BlogSidebarRecentPosts';

// ----------------------------------------------------------------------

export default function BlogSidebar({
  author, recentPosts, sx, ...other
}) {
  const isDesktop = useResponsive('up', 'md');

  return (
    <>
      {/* {author && isDesktop && <BlogSidebarAuthor author={author} />} */}

      {isDesktop && <SearchInput />}

      <Stack
        spacing={5}
        sx={{
          pt: { md: 5 },
          pb: { xs: 8, md: 0 },
          ...sx,
        }}
        {...other}
      >
        {/* <BlogSidebarCategories /> */}
        {/* <BlogSidebarRecentPosts recentPosts={recentPosts} /> */}
        <NewsSidebarPopularTags />
      </Stack>
    </>
  );
}
