/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Container, Typography,
  Grid, Box, Fade, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
  FilledInput,
  InputAdornment,
  Snackbar,
  SnackbarContent,
  TextField,
  Paper,
  Stack,
} from '@mui/material';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { LoadingButton } from '@mui/lab';
import checkMarkIcon from '@iconify/icons-carbon/checkmark-filled';
import close from '@iconify/icons-carbon/close';
import { m } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { get, isString } from 'lodash';
import Iconify from '../Iconify';

import { responsiveFontSizes } from '../../utils/getFontValue';

export const bottomText = 'New Website Coming Soon!';

const isRedirect = (source) => {
  if (isString(source)) {
    return source.includes('voiceit');
  }
  return false;
};

export default function Landing() {
  const [showVoiceIt, setShowVoiceIt] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShowVoiceIt(false);
    }, 5000);
  });
  const [openSubscriptionSnackBar, setOpenSubscriptionSnackBar] = useState(false);
  const [openNotifyDialog, setOpenNotifyDialog] = useState(false);
  const [email, setEmail] = useState('');
  const { source } = useParams();
  const [showDialog, setShowDialog] = useState(isRedirect(source));

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSubscriptionSnackBar(false);
  };
  // const [showDialog, setShowDialog] = useState(true);
  return (
    <Box
      sx={{
        // backgroundImage: `url(${LandingCoverImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
      }}
    >
      <Container sx={{
        width: '80vw',
        height: {
          xs: '360px', sm: '500px', md: '600px', lg: '70vh',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#EEF1EF',
      }}
      >
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              sx={{
                color: '#054A91',
                fontFamily: 'branding',
                fontWeight: 'bold',
                ...responsiveFontSizes({
                  xs: 68, sm: 95, md: 115, lg: 128,
                }),
              }}
            >
              <span style={{ color: '#7F5CAD' }}>En</span>
              Quanta
            </Typography>
            <Typography
              variant="h4"
              sx={{
                color: '#054A91',
                margin: '2vh 0',
                ...responsiveFontSizes({
                  xs: 22, sm: 29, md: 42, lg: 48,
                }),
              }}
            >
              {bottomText}
            </Typography>

            <FilledInput
              placeholder="Enter your email"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              endAdornment={(
                <InputAdornment position="end">
                  <Button
                    size="large"
                    variant="contained"
                    sx={{
                      backgroundColor: '#009DDC',
                      height: 56,
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    onClick={() => setOpenNotifyDialog(true)}
                  >
                    Notify Me
                  </Button>
                </InputAdornment>
              )}
              sx={{
                p: 0,
                mt: { xs: 2, md: 3 },
                width: 1,
                maxWidth: 466,
                '& .MuiFilledInput-input': {
                  py: '18px',
                  '&::placeholder': {
                    color: 'grey.500',
                  },
                },
              }}
            />
          </Grid>

        </Grid>

      </Container>
      <Box
        component="svg"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
          width: '100%',
          marginBottom: '-11px',
        }}
      >
        <path
          fill="#422A76"
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        />
      </Box>
      <Snackbar
        open={openSubscriptionSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        message="Subscription Request Sent!"
      >
        <SnackbarContent
          style={{
            backgroundColor: '#F17300',
            boxShadow: 'none',
            color: '#EEF1EF',
          }}
          message="Subscription Request Sent!"
        />
      </Snackbar>
      <Dialog
        open={openNotifyDialog}
        // onClose={}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Iconify
            aria-label="close"
            icon={close}
            onClick={() => { setOpenNotifyDialog(false); }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          />
        </DialogTitle>
        <DialogContent>
          <MailchimpSubscribe
            // eslint-disable-next-line quotes
            url="https://voiceit-tech.us10.list-manage.com/subscribe/post?u=3dd68700088d0d7861280fd49&id=a9758dca0d"
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
                alreadyTypedEmail={email}
              />
            )}
          />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions> */}
      </Dialog>
      <Dialog
        open={showDialog}
        onClose={() => { setShowDialog(false); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            backgroundColor: '#054A91',
          }}
        >
          <Container sx={{
            width: '100%',
            height: {
              xs: '200px', sm: '200px', md: '200px', lg: '200px',
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#EEF1EF',
          }}
          >
            <Grid container columnSpacing={3}>
              <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                <Typography
                  variant="h1"
                  sx={{
                    color: '#EEF1EF',
                    fontFamily: 'branding',
                    fontWeight: 'bold',
                    ...responsiveFontSizes({
                      xs: 28, sm: 42,
                    }),
                  }}
                >
                  VoiceIt is now EnQuanta
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    color: '#EEF1EF',
                    margin: '2vh 0',
                    ...responsiveFontSizes({
                      xs: 14, sm: 16,
                    }),
                  }}
                >
                  Threats are evolving, so VoiceIt became EnQuanta to stop them.
                </Typography>
              </Grid>

            </Grid>
          </Container>
        </Box>
        <Container sx={{
          alignItems: 'center',
          // px: 60,
          backgroundColor: '#EEF1EF',
          py: 3,
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          <Typography sx={{
            ...responsiveFontSizes({
              xs: 12, sm: 14,
            }),
          }}
          >
            VoiceIt has been a leader in the Biometric Security space since 1998 releasing
            the first Voice Authentication (GINA) for the Windows Operating Systems called
            VoiceSecureIt, and in 2007 released the first Biometric-as-a-Service (BaaS)
            RESTful APIs for voice biometrics worldwide. Cyber threats continued to evolve
            over the years and with quantum computing and Y2Q looming we decided to be the
            first to offer true protection of data and infrastructure from pre and post
            quantum threats. EnQuanta was born.
          </Typography>
          <Typography sx={{
            mt: 2,
            ...responsiveFontSizes({
              xs: 12, sm: 14,
            }),
          }}

          >
            EnQuanta&apos;s patent pending Quantum Hybrid Ciphers protection safeguards
            all your digital asset
            types (e.g. firmware, IP software, CPI) from a cyber breach so you can keep
            running and not worry about theft, even if your data assets are stolen or locked.
            Additionally, EnQuanta&apos;s patented biometric access protection tools mitigate
            cyber access threats.
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#F17300',
              ...responsiveFontSizes({
                xs: 12, sm: 14,
              }),
              mt: 3,
            }}
            onClick={() => { setShowDialog(false); }}
          >
            Dismiss
          </Button>
        </Container>
      </Dialog>
    </Box>
  );
}

function CustomForm({
  status, message, onValidated, alreadyTypedEmail,
}) {
  const [email, setEmail] = useState(alreadyTypedEmail);
  const [firstName, setFirstName] = useState('');

  let disableButton = false;
  let isSending = false;
  let isError = false;
  let isSuccess = false;
  let buttonText = 'Notify Me';
  if (status === 'sending') {
    disableButton = true;
    isSending = true;
    buttonText = 'Sending';
  } else if (status === 'error') {
    disableButton = false;
    isError = true;
  } else if (status === 'success') {
    disableButton = true;
    isSuccess = true;
  }
  if (isSuccess) {
    return (
      <Paper
        component={m.div}
        whileHover="hover"
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
          p: 2,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Iconify icon={checkMarkIcon} sx={{ fontSize: '50px', color: '#009DDC', my: 2 }} />
          <Typography sx={{ color: 'text.secondary' }}>
            {message}
          </Typography>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper
      component={m.div}
      whileHover="hover"
      sx={{
        borderRadius: 2,
        overflow: 'hidden',
        p: 2,
      }}
    >
      <Stack
        spacing={2}
        sx={{
          mb: isError ? 3 : 5,
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: '#054A91',
            ...responsiveFontSizes({
              xs: 24, sm: 24, md: 30, lg: 30,
            }),
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          New Website Updates

        </Typography>

        <Typography sx={{
          color: 'text.secondary',
          ...responsiveFontSizes({
            xs: 16, sm: 16, md: 18, lg: 18,
          }),
        }}
        >
          Enter info to get notified
        </Typography>

        {!isSuccess && (
          <>
            <HigherTextInput
              fullWidth
              label="Email"
              disabled={isSending}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <HigherTextInput
              fullWidth
              disabled={isSending}
              label="First Name"
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
            />
          </>
        )}
        {isError && (
          <Typography sx={{
            color: '#DC4D00',
            ...responsiveFontSizes({
              xs: 14, sm: 14, md: 14, lg: 14,
            }),
            maxWidth: '300px',
          }}
          >
            {message}
          </Typography>
        )}
      </Stack>

      {!isSuccess && (
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSending}
          disabled={disableButton}
          sx={{
            mx: { xs: 'auto !important', md: 'unset !important' },
            backgroundColor: '#F17300',
          }}
          onClick={() => {
            onValidated({
              MERGE0: email,
              MERGE1: firstName,
            });
          }}
        >
          {buttonText}
        </LoadingButton>
      )}

    </Paper>
  );
}

// TODO: make this reusable, copy pasted this
const HigherTextInput = styled(TextField)(() => ({
  '& .MuiFilledInput-input': {
    minHeight: '1.6em',
  },
}));
