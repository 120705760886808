import './App.css';
import React from 'react';
// import { SettingsProvider } from './contexts/SettingsContext';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { ThemeProvider } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  createTheme,
} from '@mui/material';
import 'aos/dist/aos.css';
import AOS from 'aos';

import ThemeProvider from './theme';
import HeaderNavBar from './components/NavBar/HeaderNavBar';
import Home from './components/Home';
import WhatIsQuantaSafe from './components/WhatIsQuantaSafe/WhatIsQuantaSafe';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import About from './components/About/About';
import Documentation from './components/Documentation/Documentation';
import BlogLanding from './components/Blog/BlogLanding';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import Branding from './components/Branding/Branding';
import NotFound from './components/NotFound/NotFound';
import FaaShutdown from './components/Blog/blogs/FAAShutdown';
import WhatIsCyberResilience from './components/Blog/blogs/WhatIsCyberResilience';
import QuantumEncryptionDecrypted from './components/Blog/blogs/QuantumEncryptionDecrypted';
import SecureVault from './components/SecureVault/SecureVault';
import NewsLanding from './components/News/NewsLanding';
import Dec10 from './components/News/news/Dec10';
import Jan14 from './components/News/news/Jan14';
import Jan7 from './components/News/news/Jan7';
import SecureStorage from './components/SecureStorage/SecureStorage';
import SecureLite from './components/SecureLite/SecureLite';
import SecureDatabase from './components/SecureDatabase/SecureDatabase';
import SecureAgent from './components/SecureAgent/SecureAgent';
import SecureTransfer from './components/SecureTransfer/SecureTransfer';
import SecureComms from './components/SecureComms/SecureComms';
import Dec0223 from './components/News/news/Dec0223';
import Nov2023 from './components/News/news/Nov2023';
// import ThemeColorPresets from './components/ThemeColorPresets';
// import RtlLayout from './components/RtlLayout';
// import MotionLazyContainer from './components/animate/MotionLazyContainer';

const theme = createTheme({
  palette: {
    primary: {
      main: '#054A91',
    },
    secondary: {
      main: '#7F5CAD',
    },
    culturedWhite: {
      main: '#EEF1EF',
    },
    carolinaBlue: {
      main: '#009DDC',
    },
    safetyOrange: {
      main: '#F17300',
    },
  },
});

function App() {
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    AOS.init({
      once: true,
      delay: 0,
      duration: 800,
      offset: 0,
      easing: 'ease-in-out',
    });
    // const handleResize = () => {
    //   AOS.refresh();
    // };

    // window.addEventListener('resize', handleResize);
    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
  }, []);
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Helmet prioritizeSeoTags>
          <title>EnQuanta - neutralize every threat</title>
          <meta name="description" content="neutralize every threat" />
        </Helmet>
        <HeaderNavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="home/:source" element={<Home />} />
          <Route path="whatIsQuantaSafe" element={<WhatIsQuantaSafe />} />
          <Route path="secureVault" element={<SecureVault />} />
          <Route path="secureStorage" element={<SecureStorage />} />
          <Route path="secureLite" element={<SecureLite />} />
          <Route path="secureDatabase" element={<SecureDatabase />} />
          <Route path="secureAgent" element={<SecureAgent />} />
          <Route path="secureTransfer" element={<SecureTransfer />} />
          <Route path="secureComms" element={<SecureComms />} />

          <Route path="news" element={<NewsLanding />} />
          <Route path="contactUs" element={<Contact />} />
          <Route path="privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="about" element={<About />} />
          <Route path="documentation" element={<Documentation />} />
          <Route path="blog" element={<BlogLanding />} />
          <Route path="blog/quantumEncryptionDecrypted" element={<QuantumEncryptionDecrypted />} />
          <Route path="blog/faaShutdown" element={<FaaShutdown />} />
          <Route path="blog/whatIsCyberResilience" element={<WhatIsCyberResilience />} />
          <Route path="news/Dec1023" element={<Dec10 />} />
          <Route path="news/Jan1424" element={<Jan14 />} />
          <Route path="news/Jan0724" element={<Jan7 />} />
          <Route path="news/Dec0223" element={<Dec0223 />} />
          <Route path="news/Nov2023" element={<Nov2023 />} />

          <Route path="termsAndConditions" element={<TermsAndConditions />} />
          <Route path="branding" element={<Branding />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
