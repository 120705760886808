import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  Avatar,
} from '@mui/material';
import { responsiveFontSizes } from '../../utils/getFontValue';
import Iconify from '../Iconify';

const featuresList = [{
  icon: 'carbon:ai-governance-lifecycle',
  title: 'Quantum Cyber Resilience',
  description: 'Securing data-at-rest to safeguard against current cyber attacks and future quantum threats.',
},
{
  icon: 'carbon:ibm-cloud-pak-applications',
  title: 'Physical Separation',
  description: 'Separation is maintained in non-volatile memory between the QuID and QCU to ensure security.',
},
{
  icon: 'carbon:iot-platform',
  title: 'Secure Transmission',
  description: 'Enhanced Encrypted TCP transmission is used to transfer the QCU to unpackage and dicrypt the QuID.',
},
{
  icon: 'carbon:ibm-cloud-event-notification',
  title: 'Versatile Design',
  description: 'Software-only solution compatible with various system and network topologies.',
},
{
  icon: 'carbon:process',
  title: 'Compliant Security',
  description: 'Each QHC stack can include a compliant National Institute of Standards and Technology (NIST) standard encryption cipher.',
},
{
  icon: 'carbon:heat-map-02',
  title: 'Volatile Memory Protection',
  description: 'Dicrypted information is stored only in volatile memory and cleared on command or automatically upon power loss.',
},
{
  icon: 'carbon:terminal',
  title: 'Software Anti-Tamper',
  description: 'QCU digital signature, unauthorized access attempt detection, and optional replay attack prevention.',
},
{
  icon: 'carbon:shape-except',
  title: 'Multi-Layer Protection',
  description: 'Advanced patent pending security framework of features work together to thwart attackers.',
}];

function SecureVaultFeatures() {
  return (
    <Grid container spacing={4}>
      {featuresList.map((item, i) => (
        <Grid item xs={12} sm={6} md={4} key={item.title}>
          <Box
            component={Card}
            padding={4}
            borderRadius={2}
            width={1}
            height={1}
            data-aos="fade-up"
            data-aos-delay={i * 100}
          >
            <Box display="flex" flexDirection="column">
              <Box
                component={Avatar}
                width={50}
                height={50}
                marginBottom={2}
                sx={{ backgroundColor: '#009DDC' }}
              >
                <Iconify icon={item.icon} sx={{ width: 32, height: 32, color: '#EEF1EF' }} />
              </Box>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  ...responsiveFontSizes({
                    xs: 22, sm: 28, md: 24, lg: 24,
                  }),
                }}
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  ...responsiveFontSizes({
                    xs: 22, sm: 28, md: 16, lg: 16,
                  }),
                }}
              >
                {item.description}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

export default SecureVaultFeatures;
