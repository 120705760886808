import React from 'react';
import { Typography, Chip, Box } from '@mui/material';

// ----------------------------------------------------------------------

const TAGS = [
  { label: 'Cyber Resilience', path: '#' },
  { label: 'Quantum Encryption', path: '#' },
  { label: 'China', path: '#' },
  { label: 'DoD', path: '#' },
  { label: 'Russia', path: '#' },
];

// ----------------------------------------------------------------------

export default function NewsSidebarPopularTags() {
  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 3, color: '#054A91' }}>
        Popular Tags
      </Typography>

      {TAGS.map((tag) => (
        <Chip key={tag.label} label={tag.label} sx={{ m: 0.5 }} onClick={() => console.log('tag clicked')} />
      ))}
    </Box>
  );
}
