/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Link,
} from '@mui/material';
import Dec0223Cover from '../../../assets/images/dec0223.jpg';

import { responsiveFontSizes } from '../../../utils/getFontValue';

export default function Dec0223() {
  return (
    <>
      <Box sx={{
        backgroundImage: `url(${Dec0223Cover})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
        marginTop: '64px',
      }}
      >
        <Container sx={{
          width: '100vw',
          height: '66vw',
          // height: {
          //   xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          // },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#EEF1EF',
        }}
        />
      </Box>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#054A91',
                  ...responsiveFontSizes({
                    xs: 34, sm: 34, md: 34, lg: 40,
                  }),
                }}
              >
                Ransomware Hits Hospitals, Diamonds In Quantum Computing
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                December 2nd, 2023
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                A major ransomware attack impacting healthcare facilities across multiple states,
                a significant advancement in diamond-based quantum bits, the ramifications of a
                cyber catastrophe at Fidelity National Financial, and Amazon&apos;s introduction of
                a new quantum chip aimed at reducing computational errors.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Ransomware Attack On Hospitals
              </Typography>
              <Typography>
                Hospitals in multiple states are diverting patients from their emergency
                rooms due toa recent cyberattack on Ardent Health Services, a major health
                system. The attack has significantly impacted the chain&apos;s operations,
                forcing some of its emergency rooms to divert patients to other hospitals.
                {' '}
                <Link
                  href="https://thehill.com/policy/technology/4331111-ransomware-attack-forces-hospitals-in-multiple-states-to-divert-some-emergency-room-patients/"
                  target="_blank"
                  rel="noopener"
                >
                  [The Hill]
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Amazon&apos;s Quantum Leap
              </Typography>
              <Typography>
                Amazon has made a significant leap in quantum computing by introducing
                a new quantum chip designed to reduce computational errors. This
                innovation could mark a pivotal moment in the quantum computing
                industry, potentially leading to more efficient and accurate quantum
                computers. The chip&apos;s development highlights the ongoing efforts in
                enhancing quantum technology and its applications. For an in-depth
                look at this breakthrough.
                {' '}
                <Link
                  href="https://www.forbes.com/sites/craigsmith/2023/11/28/amazon-introduces-new-quantum-chip-to-reduce-errors/?sh=473696b41f58"
                  target="_blank"
                  rel="noopener"
                >
                  [Forbes]
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Fidelity&apos;s Ransomware Nightmare
              </Typography>
              <Typography>
                Fidelity National Financial, a major player in the financial sector,
                has been hit by a devastating ransomware attack. The incident has
                caused widespread panic among homeowners and buyers, highlighting the
                increasing vulnerabilities in financial institutions to cyber threats.
                This event underscores the urgent need for enhanced digital
                security measures in the financial industry.
                {' '}
                <Link
                  href="https://techcrunch.com/2023/11/27/ransomware-catastrophe-at-fidelity-national-financial-causes-panic-with-homeowners-and-buyers/?cx_testId=6&cx_testVariant=cx_undefined&cx_artPos=1#cxrecs_s"
                  target="_blank"
                  rel="noopener"
                >
                  [TechCrunch]
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Diamonds In Quantum Computing
              </Typography>

              <Typography>
                A groundbreaking discovery in quantum computing has been made with the
                development of diamond quantum bits. This new technology represents a
                significant advancement in the field, potentially leading to more stable
                and efficient quantum computers. This innovation opens new possibilities
                for quantum computing applications and is a testament to the
                rapid progress in this domain.
                {' '}
                <Link
                  href="http://phys.org/"
                  target="_blank"
                  rel="noopener"
                >
                  [Phys.org]
                </Link>
              </Typography>
            </Grid>

          </Grid>
        </Container>
      </Box>

    </>
  );
}
