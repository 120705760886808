/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Paper,
} from '@mui/material';
import WAVES from 'vanta/dist/vanta.waves.min';
import getWindowSize from '../../utils/getScreenSize';

import WhatIsQuantaSafeCoverImage from '../../assets/images/CoverImages/QuantaSafeCoverImage.png';
import { responsiveFontSizes } from '../../utils/getFontValue';
import QuantumIntelligentData from '../../assets/images/AllProducts.png';
import TamperResilienceAPI from '../../assets/images/TamperResilienceAPI.png';
import SecureStorage from '../../assets/images/SecureStorage.png';
import SecureComms from '../../assets/images/SecureComms.png';
import SecureVault from '../../assets/images/SecureVault.png';
import SecureTransfer from '../../assets/images/SecureTransfer.png';
import SecureLite from '../../assets/images/SecureLite.png';
import SecureAgent from '../../assets/images/SecureAgent.png';

// TODO: Check if this is needed anymore3
import '../EnquantaAdvantage/EnquantaAdvantage.css';
import StartOurCoPilotBox from '../StartOurCoPilotBox/StartOurCoPilotBox';
// import AppCarousel from './AppCarousel';

// TODO: clean up this doo doo

const regularTextFontSizes = {
  xs: 16, sm: 18, md: 18, lg: 18,
};

const solutions = [
  {
    text:
      // eslint-disable-next-line react/jsx-indent
      <>
        Secure Storage
      </>,
  },
  {
    text:
      // eslint-disable-next-line react/jsx-indent
      <>
        Secure Vault
      </>,
  },
  {
    text:
      // eslint-disable-next-line react/jsx-indent
      <>
        Secure Transfer
      </>,
  },
  {
    text:
      // eslint-disable-next-line react/jsx-indent
      <>
        Secure Comms
      </>,
  },
  {
    text:
      // eslint-disable-next-line react/jsx-indent
      <>
        Secure Lite
      </>,
  },
  {
    text:
      // eslint-disable-next-line react/jsx-indent
      <>
        Secure Database
      </>,
  },
  {
    text:
      // eslint-disable-next-line react/jsx-indent
      <>
        Secure Agent
      </>,
  },
];

const defaultSVGDims = {
  height: { xs: 60, sm: 80, md: 105 },
};
function SolutionsCard({
  icon, text, svgDims, onClick,
}) {
  return (
    <Paper
      variant="outlined"
      onClick={() => onClick()}
      sx={{
        borderRadius: 2.5,
        borderStyle: 'solid',
        borderWidth: '4px',
        borderColor: 'rgba(145, 158, 171, 0.24)',
        // backgroundColor: '#054A91',
        ':hover': {
          boxShadow: 20, // theme.shadows[20]
          backgroundColor: '#F1F3F1',
        },
        p: {
          xs: 3, sm: 3, md: 3, lg: 5,
        },
        pb: {
          xs: 4, sm: 4, md: 3, lg: 5,
        },
        // height: '320px',
      }}
    >
      <Box sx={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        mb: { xs: 1, md: 1, lg: 2 },
        '& > img': svgDims || defaultSVGDims,
      }}
      >
        {icon}
      </Box>
      <Typography sx={{
        textAlign: 'center',
        fontWeight: 'bold',
        ...responsiveFontSizes({
          xs: 14, sm: 18, md: 18, lg: 18,
        }),
      }}
      >
        {text}
      </Typography>
    </Paper>
  );
}

function Intro({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      QuantaSafe is a revolutionary new technology to combat cyber threats.
      This cutting-edge incryption technology is built upon EnQuanta&apos;s patent-pending
      {' '}
      <span style={{ fontWeight: 'bold', color: '#7F5CAD' }}>Quantum Hybrid Ciphers</span>
      {' '}
      providing Quantum cyber resilience,
      which can be easily integrated into software or
      firmware systems for comprehensive protection
      against both present-day and post-quantum threats.
    </Typography>
  );
}

function BodyParagraph1({ sx }) {
  return (
    <>
      <Typography sx={{
        marginBottom: '10px',
        ...responsiveFontSizes(regularTextFontSizes),
        ...sx,
      }}
      >
        QuantaSafe uses the power of true quantum entropy randomization and proprietary
        {' '}
        <span style={{ fontWeight: 'bold', color: '#7F5CAD' }}>Quantum Hybrid Ciphers</span>
        {' '}
        (QHCs) to
        secure data, providing a simple,
        yet powerful shield against the theft of digital assets.
        This innovative approach combines state-of-the-art
        technologies into a suite of tools designed to
        simplify the process of achieving future-proof data security.
      </Typography>

      <Typography sx={{
        marginBottom: '10px',
        ...responsiveFontSizes(regularTextFontSizes),
        ...sx,
      }}
      >
        Our term “Incryption” is somewhat analogous to “Encryption”, but encompasses
        our expanded cryptographic agile process that can include one or more standard
        encryption algorithms plus a random order of our non-standard cipher processes.
      </Typography>
    </>

  );
}

function BodyParagraph2({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      The technology behind QuantaSafe ensures that data is protected,
      even in the event of a cyber breach. By using post-quantum techniques,
      Quantum Hybrid Ciphers provide
      {' '}
      <span style={{ fontWeight: 'bold', color: '#7F5CAD' }}>Anti-Tamper</span>
      {' '}
      and
      {' '}
      <span style={{ fontWeight: 'bold', color: '#7F5CAD' }}> Quantum Resilience</span>
      {', '}
      allowing for the effective obfuscation and protection of data
      that renders it useless while at rest or in transit.
    </Typography>
  );
}

function BodyParagraph3({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      Unlike other security systems, data that is protected by QuantaSafe
      is immune to brute force attacks and data recovery efforts.
      The
      {' '}
      <span style={{ fontWeight: 'bold', color: '#7F5CAD' }}>API</span>
      , which facilitates secure data delivery,
      is an easy installation as a software-only OSI Layer 6 API call.
    </Typography>
  );
}

function Conclusion({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      QuantaSafe is a breakthrough technology,
      which combines the most advanced QHCs
      and cutting-edge software tools, offering unparalleled data protection
      for individuals, businesses, and governments alike.
    </Typography>
  );
}

export default function WhatIsQuantaSafe() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const [vantaEffect, setVantaEffect] = useState(null);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(WAVES({
        el: myRef.current,
        color: 0x251742,
        shininess: 75.00,
        waveHeight: 16.00,
        waveSpeed: 1.00,
        zoom: 0.93,
      }));
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  let quantumIntelligenceDiagramHeight = '250px';
  let tamperResilienceAPIDiagramHeight = '300px';
  if (windowSize.innerWidth >= 1200) {
    quantumIntelligenceDiagramHeight = '370px';
    tamperResilienceAPIDiagramHeight = '450px';
  } else if (windowSize.innerWidth >= 900) {
    quantumIntelligenceDiagramHeight = '320px';
    tamperResilienceAPIDiagramHeight = '390px';
  } else if (windowSize.innerWidth >= 600) {
    quantumIntelligenceDiagramHeight = '300px';
    tamperResilienceAPIDiagramHeight = '300px';
  }
  return (
    <>

      <Box
        ref={myRef}
        sx={{
          backgroundImage: `url(${WhatIsQuantaSafeCoverImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: '100% 100%',
          marginTop: '64px',

        }}
      >
        <Container sx={{
          width: '100vw',
          height: {
            xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#EEF1EF',
        }}
        >
          <Grid container columnSpacing={3}>
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
              <Typography
                variant="h1"
                sx={{
                  color: '#EEF1EF',
                  fontFamily: 'branding',
                  fontWeight: 'bold',
                  ...responsiveFontSizes({
                    xs: 40, sm: 70, md: 80, lg: 96,
                  }),
                }}
              >
                What is QuantaSafe?
              </Typography>

            </Grid>

          </Grid>
        </Container>
      </Box>

      <Box sx={{
        height: 'auto',
        minHeight: { md: '100vh' },
        '@media (min-width: 600px)': {
          paddingLeft: '24px',
          paddingRight: '24px',
        },
        backgroundColor: '#EEF1EF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 9 },
          pb: { xs: 3, md: 3, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={{
              xs: 0, sm: 0, md: 0, lg: 3,
            }}
            sx={{ justifyContent: 'center' }}
          >
            <Grid item md={6}>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: '5px',
                  ...responsiveFontSizes({
                    xs: 22, sm: 28, md: 28, lg: 36,
                  }),
                  color: '#054A91',
                  fontWeight: 'bold',
                }}
              >
                Quantum Incrypted Data.
              </Typography>
              <Intro />
              {windowSize.innerWidth < 900 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    py: 2,
                    my: { xs: 0, md: 4, lg: 2 },
                  }}
                >
                  <img src={QuantumIntelligentData} alt="QuantaSafe" style={{ height: quantumIntelligenceDiagramHeight }} />
                </Box>
              )}
              <BodyParagraph1 />
            </Grid>

            {windowSize.innerWidth >= 900
              && (
                <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      py: 1,
                      display: 'flex',
                    }}
                  >
                    <img src={QuantumIntelligentData} alt="QuantaSafe" style={{ height: quantumIntelligenceDiagramHeight }} />
                  </Box>
                </Grid>
              )}

            {windowSize.innerWidth >= 900
              && (
                <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      py: 1,
                      display: 'flex',
                    }}
                  >
                    <img src={TamperResilienceAPI} alt="QuantaSafe" style={{ height: tamperResilienceAPIDiagramHeight }} />
                  </Box>
                </Grid>
              )}
            <Grid item md={6}>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: '5px',
                  ...responsiveFontSizes({
                    xs: 22, sm: 28, md: 28, lg: 36,
                  }),
                  color: '#054A91',
                  fontWeight: 'bold',
                }}
              >
                Anti-Tamper. Resilience. API.
              </Typography>
              <BodyParagraph2 />
              {windowSize.innerWidth < 900 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    py: 2,
                    my: { xs: 0, md: 4, lg: 2 },
                  }}
                >
                  <img src={TamperResilienceAPI} alt="QuantaSafe" style={{ height: tamperResilienceAPIDiagramHeight }} />
                </Box>
              )}
              <BodyParagraph3 />
              <Conclusion />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                mt: {
                  xs: 3, sm: 3, md: 3, lg: 1,
                },
                mb: 3,
              }}
            >

              <Grid
                container
              >
                <Grid
                  item
                  xs={12}
                  md={7}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      mb: 3,
                      ...responsiveFontSizes({
                        xs: 22, sm: 28, md: 28, lg: 36,
                      }),
                      color: '#054A91',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    QuantaSafe Product Line
                  </Typography>
                  <Box
                    sx={{
                      display: 'grid',
                      rowGap: { xs: 2, sm: 4 },
                      columnGap: { xs: 2, sm: 4 },
                      pr: { xs: 0, lg: 0 },
                      gridTemplateColumns: {
                        xs: 'repeat(1, 1fr)',
                        sm: 'repeat(1, 1fr)',
                        md: 'repeat(2, 1fr)',
                      },
                      mr: { xs: 0, md: 4 },
                      mb: 3,
                    }}
                  >
                    {/* {solutions.map((solution) => (
                  <SolutionsCard solution={solution} />
                ))} */}
                    <SolutionsCard
                      icon={<img src={SecureVault} alt="Cold Storage Protection" />}
                      svgDims={{
                        height: { xs: '70px', sm: '80px', md: '105px' },
                      }}
                      text={solutions[1].text}
                      onClick={() => { window.open('/secureVault', '_blank', 'noreferrer'); }}
                    />
                    <SolutionsCard
                      icon={<img src={SecureStorage} alt="Data in transit" />}
                      svgDims={{
                        height: { xs: '70px', sm: '80px', md: '105px' },
                      }}
                      text={solutions[0].text}
                      onClick={() => { window.open('/secureStorage', '_blank', 'noreferrer'); }}
                    />

                    <SolutionsCard
                      icon={<img src={SecureTransfer} alt="Anti-tamper Firmware" />}
                      svgDims={{
                        height: { xs: '70px', sm: '80px', md: '105px' },
                      }}
                      text={solutions[2].text}
                      onClick={() => { window.open('/secureTransfer', '_blank', 'noreferrer'); }}
                    />

                    <SolutionsCard
                      icon={<img src={SecureComms} alt="IP software" />}
                      svgDims={{
                        height: { xs: '70px', sm: '80px', md: '105px' },
                      }}
                      text={solutions[3].text}
                      onClick={() => { window.open('/secureComms', '_blank', 'noreferrer'); }}
                    />
                    <SolutionsCard
                      icon={<img src={SecureLite} alt="IP software" />}
                      svgDims={{
                        height: { xs: '70px', sm: '80px', md: '105px' },
                      }}
                      text={solutions[3].text}
                      onClick={() => { window.open('/secureLite', '_blank', 'noreferrer'); }}
                    />
                    <SolutionsCard
                      icon={<img src={SecureAgent} alt="IP software" />}
                      svgDims={{
                        height: { xs: '70px', sm: '80px', md: '105px' },
                      }}
                      text={solutions[3].text}
                      onClick={() => { window.open('/secureAgent', '_blank', 'noreferrer'); }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{
                    mt: { md: 8, lg: 10 },
                  }}
                >
                  <StartOurCoPilotBox />
                </Grid>
              </Grid>

            </Grid>

          </Grid>

        </Container>
      </Box>
    </>
  );
}
