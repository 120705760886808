/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Link,
} from '@mui/material';
import FaaShutdownCoverImage from '../../../assets/images/dec10.jpg';

import { responsiveFontSizes } from '../../../utils/getFontValue';

export default function Dec10() {
  return (
    <>
      <Box sx={{
        backgroundImage: `url(${FaaShutdownCoverImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
        marginTop: '64px',
      }}
      >
        <Container sx={{
          width: '100vw',
          height: '66vw',
          // height: {
          //   xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          // },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#EEF1EF',
        }}
        />
      </Box>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#054A91',
                  ...responsiveFontSizes({
                    xs: 34, sm: 34, md: 34, lg: 40,
                  }),
                }}
              >
                Quantum Alliance Sparks Cyber Alert
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                December 10th, 2023
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                A cyberattack on Austal USA by the Hunters International group reveals
                risks in defensesector cybersecurity. Apple&apos;s report on 2.6 billion
                compromised records highlights data security challenges. The USS Carney&apos;s
                action against drone and missile threats in the Red Sea showcases naval response
                capabilities. The Axios report on AI and robotics in U.S. military strategy
                indicates evolving defense technologies.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Navy Contractor Hit By Cyberattack
              </Typography>
              <Typography>
                Austal USA, a key shipbuilding company for the U.S. Department of Defense,
                has confirmed a cyberattack, with the Hunters International ransomware group
                claiming responsibility. Austal USA specializes in aluminum vessels and has
                contracts worth billions with the U.S. Navy and Coast Guard. The company acted
                quickly to mitigate the incident, ensuring no operational impact or access to
                personal or classified information. The extent of stolen data remains under
                investigation.
                {' '}
                <Link
                  href="https://www.bleepingcomputer.com/news/security/navy-contractor-austal-usa-confirms-cyberattack-after-data-leak/#google_vignette"
                  target="_blank"
                  rel="noopener"
                >
                  [Bleeping Computer]
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                2.6 Billion Records Breached
              </Typography>
              <Typography>
                A recent study by Dr. Stuart Madnick of MIT reveals a troubling rise in data
                breaches, with 2.6 billion personal records exposed in just the
                past two years. The study emphasizes the increasing importance of robust
                cloud data protections, such as end-to-end encryption. Apple, in response,
                has enhanced its iCloud security with Advanced Data Protection,
                expanding the encryption coverage to 23 data categories.
                {' '}
                <Link
                  href="https://www.apple.com/in/newsroom/2023/12/report-2-point-6-billion-records-compromised-by-data-breaches-in-past-two-years/"
                  target="_blank"
                  rel="noopener"
                >
                  [Apple]
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                U.S. Warships Attacked
              </Typography>
              <Typography>
                The U.S. Navy destroyer Carney was involved in a critical incident in
                the Red Sea, responding to attacks on commercial vessels by drones and
                missiles. The attacks, claimed by Yemen&apos;s Houthi rebels and enabled by
                Iran according to U.S. Central Command, targeted multiple vessels.
                Carney successfully shot down several drones and provided assistance
                to the attacked ships.
                {' '}
                <Link
                  href="https://www.usatoday.com/story/graphics/2023/12/05/how-us-warship-attack-red-sea-unfolded/71799351007/"
                  target="_blank"
                  rel="noopener"
                >
                  [USA Today]
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                U.S. Not Ready
              </Typography>

              <Typography>
                The future of warfare is rapidly evolving with the integration of artificial
                intelligence and robotics. U.S. military leadership faces critical challenges
                in adapting to this new era of combat. The autonomous Roadrunner-M system,
                recently unveiled, epitomizes the shift towards more automated warfare. However,
                experts are concerned that the U.S. is not mobilizing fast enough due to
                outdated procurement processes and a reluctance to move away from traditional
                military hardware. The rise of AI and technology in warfare necessitates a new
                approach, focusing more on software and less on conventional hardware. The race
                is on to recruit top tech talent and invest in advanced systems to stay ahead of
                adversaries like China, who are rapidly advancing in areas like hypersonic
                missiles and quantum technologies.
                {' '}
                <Link
                  href="https://www.axios.com/2023/12/04/artificial-intelligence-us-military-weapons"
                  target="_blank"
                  rel="noopener"
                >
                  [Axios]
                </Link>
              </Typography>
            </Grid>

          </Grid>
        </Container>
      </Box>

    </>
  );
}
