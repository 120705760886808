/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Link,
} from '@mui/material';
import FaaShutdownCoverImage from '../../../assets/images/jan7.jpg';

import { responsiveFontSizes } from '../../../utils/getFontValue';

export default function Jan7() {
  return (
    <>
      <Box sx={{
        backgroundImage: `url(${FaaShutdownCoverImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
        marginTop: '64px',
      }}
      >
        <Container sx={{
          width: '100vw',
          height: '66vw',
          // height: {
          //   xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          // },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#EEF1EF',
        }}
        />
      </Box>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#054A91',
                  ...responsiveFontSizes({
                    xs: 34, sm: 34, md: 34, lg: 40,
                  }),
                }}
              >
                Quantum Alliance Sparks Cyber Alert
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                January 7th, 2024
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                A quantum link between China and Russia marks a leap in secure communications,
                while the escalating need for post-quantum cryptography highlights growing
                quantum threats. Major internet outages and ongoing cyber espionage activities
                reveal the fragility of global networks. Meanwhile, significant breaches in
                law firms underscore the persistent risks across sectors, reflecting the
                urgent need for advanced cybersecurity solutions.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                China And Russia Establish Quantum Link
              </Typography>
              <Typography>
                China and Russia have reportedly made significant strides in establishing a
                quantum communications link, deemed virtually unhackable, marking a considerable
                advancement in secure global communications. This joint venture promises
                to bolster defenses against cyber espionage and secure sensitive communications
                across vast distances.
                {' '}
                <Link
                  href="https://www.newsweek.com/china-russia-quantum-satellite-communications-link-unhackable-1857666"
                  target="_blank"
                  rel="noopener"
                >
                  [Newsweek]
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Orange Spain Loses Control
              </Typography>
              <Typography>
                Orange Spain recently experienced a significant internet outage affecting
                thousands of users. Investigations traced the incident back to the hacking
                of a RIPE account, demonstrating the cascading effects a single breach can
                have on broader network services. This incident is a stark reminder of the
                need for fortified cybersecurity measures across all digital interaction points.
                {' '}
                <Link
                  href="https://www.securityweek.com/ripe-account-hacking-leads-to-major-internet-outage-at-orange-spain/"
                  target="_blank"
                  rel="noopener"
                >
                  [SecurityWeek]
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Russian Hackers In Ukraine&apos;s Telecom Network
              </Typography>
              <Typography>
                Cybersecurity officials have disclosed that Russian hackers maintained a
                presence inside a major Ukraine telecoms network for months.
                This prolonged unauthorized access allowed the hackers to conduct
                extensive espionage and potentially prepare for disruptive cyberattacks.
                The incident underscores the ongoing cybersecurity challenges and geopolitical
                tensions manifesting in the digital domain.
                {' '}
                <Link
                  href="https://www.reuters.com/world/europe/russian-hackers-were-inside-ukraine-telecoms-giant-months-cyber-spy-chief-2024-01-04/"
                  target="_blank"
                  rel="noopener"
                >
                  [Reuters]
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Orrick Law Firm Faces Data Breach
              </Typography>

              <Typography>
                Orrick, a prominent international law firm, recently confirmed a data breach
                compromising sensitive information. The breach highlights the persistent
                cybersecurity threats faced by organizations across sectors, including the
                legal industry known for handling confidential client data. The incident serves
                as a critical reminder of the importance of robust cybersecurity strategies
                and practices.
                {' '}
                <Link
                  href="https://techcrunch.com/2024/01/04/orrick-law-firm-data-breach/"
                  target="_blank"
                  rel="noopener"
                >
                  [TechCrunch]
                </Link>
              </Typography>
            </Grid>

          </Grid>
        </Container>
      </Box>

    </>
  );
}
