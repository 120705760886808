/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
} from '@mui/material';
import WhatIsCyberResilienceCoverImage from '../../../assets/images/CoverImages/QuantumEncryptionDecryptedCoverImage.jpg';
import { responsiveFontSizes } from '../../../utils/getFontValue';

export default function QuantumEncryptionDecrypted() {
  return (
    <>
      <Box sx={{
        backgroundImage: `url(${WhatIsCyberResilienceCoverImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
        marginTop: '64px',
      }}
      >
        <Container sx={{
          width: '100vw',
          height: '66vw',
          // height: {
          //   xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          // },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#EEF1EF',
        }}
        />
      </Box>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#054A91',
                  ...responsiveFontSizes({
                    xs: 34, sm: 34, md: 34, lg: 40,
                  }),
                }}
              >
                Quantum Encryption Decrypted
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                What is photon entropy?
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                In the rapidly developing world of quantum computing and beyond,
                security is paramount. That is why quantum encryption uses photon entropy,
                the process by which particles are randomly generated to create
                a highly secure cryptographic key. But how does it work? Let&apos;s take a closer
                look at what photon entropy is and how it is used in quantum encryption.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#054A91',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                What is meant by Random?
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                What is labeled as a “random” in all contemporary cryptography is not truly random,
                but pseudorandom. Correct behavior is always deterministic, and random algorithms
                still follow this while generating seemingly random values by following concepts
                of mathematical distribution as the seed. As such, systems that require true
                randomness to ensure their integrity of the security they provide are vulnerable to
                attacks that can determine a seemingly random value by understanding
                how it was generated, and exploiting the deterministic characteristics
                of pseudorandomness.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#054A91',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                What is Photon Entropy?
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                At its most basic level, entropy is randomness or disorder or chaos. In physics,
                entropy measures the number of microstates that make up a macrostate of an isolated
                system. Entropy can be measured in terms of bits—the higher the number of bits,
                the more unpredictable the system becomes and thus the more secure it is.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                In quantum encryption, photon entropy measures the amount of randomness that can be
                generated from photons (the elementary particles of light) released from a
                single source. When these photons are randomly emitted by the source they
                become unpredictable – making them perfect for use in cryptography
                as part of an encryption key.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#054A91',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                How Does Photon Entropy Work?
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                Let us consider quantum encryption as a contract that involves two parties:
                Alice and Bob. Alice sends a stream of single photons to Bob via fiber optic
                cables or free-space channeling through laser beams; each photon carries one
                bit (0 or 1). Bob then measures each photon using multiple detectors to determine
                whether it represents a 0 or 1; this information is combined into a secret key
                known only to Alice and Bob—this secret key is then used to encode their messages.

              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                The strength of this system lies in its randomness—each time Alice sends a stream
                of photons to Bob, they are different due to their random emission characteristics;
                this makes them incredibly difficult for any third party to intercept without
                detection because they cannot predict what will come next in the sequence.
                This is why photon entropy has become so popular in modern cryptography—it allows
                users to exchange secure messages without fear that someone else will be able to
                decrypt them easily.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#054A91',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                Conclusion:
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                Photon entropy has revolutionized modern cryptography with its ability to
                produce highly secure keys for message encryption. By randomly emitting photons
                from a single source and measuring them with multiple detectors, Alice and Bob
                are able to create an unpredictable sequence that only they know—making it nearly
                impossible for anyone else to intercept their messages without detection.
                With its growing popularity, it’s clear that photon entropy will continue to play
                an important role in quantum computing for years to come.
              </Typography>
            </Grid>
          </Grid>

        </Container>
      </Box>

    </>
  );
}
