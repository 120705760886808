/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  Avatar,
  CardContent,
  Stack,
} from '@mui/material';
import { responsiveFontSizes } from '../../utils/getFontValue';
import Iconify from '../Iconify';
import SecureVaultUseCase1Image from '../../assets/images/SecureVaultUseCase1.gif';
import SecureVaultUseCase2Image from '../../assets/images/SecureVaultUseCase2.png';
import SecureVaultUseCase3Image from '../../assets/images/SecureVaultUseCase3.png';

const useCasesList = [{
  imageSrc: SecureVaultUseCase1Image,
  title: 'Single-Node Primary System',
  description: 'Ideal for an independent system where the QuID is stored on the node, and the QCU is stored on secure removable media. This setup allows for the secure booting and operation of the system, dicrypting and then utilizing sensitive data as needed directly from volatile memory.',
},
{
  imageSrc: SecureVaultUseCase2Image,
  title: 'Primary-Secondary System',
  description: 'Suitable for distributed systems where the QuIDs are individually stored on a secondary node, and a primary node distributes QCUs to the secondary nodes to unpackage the QuIDs. This setup allows for the secure booting and operation of the secondary nodes, dicrypting and then utilizing sensitive data as needed directly from volatile memory.',
},
{
  imageSrc: SecureVaultUseCase3Image,
  title: 'Bridging',
  description: 'Facilitates secure data access in systems where direct communication between primary and secondary nodes is not possible. Bridge nodes assist in the retrieval and update of QCUs, enabling secure data unpackaging and use in isolated or segmented network environments.',
}];

function UseCase({
  imageSrc, title, description, imageOnLeft,
}) {
  return (
    <Box
      padding={{ xs: 2, sm: 4 }}
      borderRadius={2}
      sx={{ backgroundColor: '#E2EDEE' }}
      data-aos="fade-up"
    >
      <Grid
        container
        spacing={4}
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
      >
        <Grid item xs={12} md={8} order={imageOnLeft ? 1 : 2}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
            >
              <Box
                component="img"
                loading="lazy"
                height={1}
                width={1}
                src={imageSrc}
                alt="..."
                borderRadius={2}
                maxWidth={1}
                maxHeight={400}
                sx={{
                  objectFit: 'cover',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} order={imageOnLeft ? 2 : 1}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent={{ xs: 'flex-start', md: 'space-between' }}
            height={1}
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                {title}
              </Typography>
              <Typography>
                {description}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

function SecureVaultUseCases() {
  return (
    <Stack spacing={4}>
      <UseCase
        imageSrc={useCasesList[0].imageSrc}
        title={useCasesList[0].title}
        description={useCasesList[0].description}
        imageOnLeft
      />
      <UseCase imageSrc={useCasesList[1].imageSrc} title={useCasesList[1].title} description={useCasesList[1].description} />
      <UseCase imageSrc={useCasesList[2].imageSrc} title={useCasesList[2].title} description={useCasesList[2].description} imageOnLeft />
    </Stack>
  );
}

export default SecureVaultUseCases;
