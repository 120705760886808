import React from 'react';
import {
  Box, Stack, Button, AppBar, Divider, Container, Toolbar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/Logo.png';
import HexaLogoAnimated from '../../assets/images/HexaLogoAnimated.gif';

import { useResponsive } from '../../hooks';
import NavMobile from './NavMobile';

export default function HeaderNavBar() {
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'md');
  return (
    <AppBar
      color="primary"
      sx={{
        color: '#FFFFFF',
        backgroundColor: 'rgba(238, 241, 239, 0.9)',
        backdropFilter: 'blur(6px)',
      }}
    >
      <Toolbar sx={{ color: '#FFFFFF', backgroundColor: 'transparent', padding: '14px' }}>
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: '0',
            paddingRight: '6px',
            '@media (min-width: 600px)': {
              paddingLeft: '16px',
              paddingRight: '24px',
            },
          }}
        >
          <Box sx={{ lineHeight: 0, position: 'relative' }}>
            <Button
              variant="text"
              onClick={() => {
                navigate('/');
              }}
              sx={{ paddingLeft: '8' }}
            >
              <img src={HexaLogoAnimated} style={{ width: '24px', paddingTop: '2px', marginRight: '3px' }} alt="Hexagon logo" />
              <img src={Logo} style={{ width: '120px', paddingTop: '5px', marginLeft: '5px' }} alt="Enquanta logo" />
            </Button>
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Stack spacing={2} direction="row" alignItems="center">

            <Divider orientation="vertical" sx={{ height: 24 }} />
            {isDesktop
              && (
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    href=""
                    target="_blank"
                    rel="noopener"
                    sx={{
                      backgroundColor: '#054A91',
                      fontSize: '1rem',
                      '&:hover': {
                        backgroundColor: '#2474B5 !important',
                        // color: '#637381',
                      },
                    }}
                    onClick={() => {
                      navigate('contactUs');
                    }}
                  >
                    Contact Us
                  </Button>
                </Stack>
              )}

            {!isDesktop && (
              <NavMobile />
            )}

          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  );
}
