/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Link,
} from '@mui/material';
import Nov2023Cover from '../../../assets/images/nov2023.jpg';

import { responsiveFontSizes } from '../../../utils/getFontValue';

export default function Nov2023() {
  return (
    <>
      <Box sx={{
        backgroundImage: `url(${Nov2023Cover})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
        marginTop: '64px',
      }}
      >
        <Container sx={{
          width: '100vw',
          height: '66vw',
          // height: {
          //   xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          // },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#EEF1EF',
        }}
        />
      </Box>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#054A91',
                  ...responsiveFontSizes({
                    xs: 34, sm: 34, md: 34, lg: 40,
                  }),
                }}
              >
                Health Data Breach, Toyota Cyberattack, SSH Risks
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                November 20th, 2023
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                A massive data breach impacting millions of patients at Postmeds and Truepill
                ripples through healthcare startups. SSH connections see groundbreaking
                vulnerabilities come to light. Another Citrix Bleed Breach, this time
                hitting Toyota Financial Services. Lockbit Group gets a payout from ICBC.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Pharmacy Data Breach
              </Typography>
              <Typography>
                Over 2.3 million individuals had their sensitive health information compromised
                in a cyberattack on Postmeds, the parent company of online pharmacy
                startup Truepill. The stolen data includes patient names, birth dates,
                medication types, and prescribers&apos; names, potentially revealing sensitive
                medical information. Postmeds has begun notifying affected individuals,
                and the breach has caused concern among various healthcare startups relying on
                Postmeds for prescription fulfillment. Truepill&apos;s reach extends to
                telehealth services like Hims, GoodRx, and others.
                {' '}
                <Link
                  href="https://techcrunch.com/2023/11/18/postmeds-truepill-data-breach-pharmacy-millions/?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAADUJzvThDfWfFqjqT-p946ZOvquj6w_TFtKIlfSwju3oWa0IxdQl0duu0bkDvse0Tx1dBm0QnbxFJuxRiaP1o5Z96eBjyYMR47snBZoHnBhPIAo0a0t2yyNkGa9l9slwQhGZnnDyDA_2T3-BegZrXLpeE6s38cSBkFYMuusT8lrw"
                  target="_blank"
                  rel="noopener"
                >
                  [TechCrunch]
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                SSH Key Compromise
              </Typography>
              <Typography>
                A recent academic study has highlighted a potential risk in SSH connections,
                where an attacker can passively snoop and impersonate devices by deducing
                their private RSA keys. This vulnerability could allow attackers to monitor
                user activities and login details. It is noted that popular software libraries
                like OpenSSL and LibreSSL are not vulnerable to this method.
                The vulnerability is limited to certain Internet-of-Things and embedded devices
                using RSA keys. The research team from the University of California, San Diego,
                and MIT has shown that more than 4,900 flawed signatures could reveal private
                RSA keys, highlighting a significant but limited security concern.
                {' '}
                <Link
                  href="https://www.theregister.com/2023/11/14/passive_ssh_key_compromise/"
                  target="_blank"
                  rel="noopener"
                >
                  [The Register]
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Toyota Cyberattack
              </Typography>
              <Typography>
                Toyota Financial Services (TFS) has confirmed unauthorized access to its systems
                in Europe and Africa, following a claim by the Medusa ransomware gang.
                The breach&apos;s extent is yet to be fully confirmed, but the attackers have
                demanded an $8 million ransom and released sample data including financial
                documents and personal information. TFS, a subsidiary of Toyota Motor Corporation,
                has taken affected systems offline and is working with law enforcement.
                The breach may be linked to the Citrix Bleed vulnerability, as indicated
                by security analyst Kevin Beaumont.
                {' '}
                <Link
                  href="https://www.bleepingcomputer.com/news/security/toyota-confirms-breach-after-medusa-ransomware-threatens-to-leak-data/"
                  target="_blank"
                  rel="noopener"
                >
                  [BleepingComputer]
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                ICBC Ransom Payment
              </Typography>

              <Typography>
                The Industrial and Commercial Bank of China (ICBC), China&apos;s largest lender,
                reportedly paid a ransom following a cyber attack by the ransomware group
                Lockbit. This attack, which occurred on November 9, targeted ICBC&apos;s US arm,
                causing significant disruptions in trades within the US Treasury market.
                The Lockbit representative claimed that ICBC settled the ransom demand
                through an online messaging app. The attack had a severe operational impact,
                creating a temporary $9 billion debt to BNY Mellon and disrupting
                the firm&apos;s corporate email system.
                {' '}
                <Link
                  href="https://www.insurancebusinessmag.com/us/news/cyber/icbc-hacker-says-ransom-was-paid-following-major-breach-466924.aspx"
                  target="_blank"
                  rel="noopener"
                >
                  [InsuranceBusinessMag]
                </Link>
              </Typography>
            </Grid>

          </Grid>
        </Container>
      </Box>

    </>
  );
}
