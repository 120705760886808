/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Container,
  Stack,
  Avatar,
  Grid,
  Divider,
} from '@mui/material';
import format from 'date-fns/format';
import { useNavigate } from 'react-router-dom';
import Image from '../Image';
import TuckerAvatar from '../../assets/images/Tucker.jpg';
import NoelAvatar from '../../assets/images/Noel.png';
import RooAvatar from '../../assets/images/Roo.jpg';
import ArmaanAvatar from '../../assets/images/Armaan.jpg';
import FaaShutdownSmallCoverImage from '../../assets/images/jan7.jpg';
import Dec0223Cover from '../../assets/images/dec0223.jpg';
import Nov2023Cover from '../../assets/images/nov2023.jpg';
import QuantumEncryptionDecryptedCoverImage from '../../assets/images/CoverImages/QuantumEncryptionDecryptedCoverImage.jpg';
import Jan14CoverImage from '../../assets/images/jan14.jpg';
import WhatIsCyberResilienceCoverImage from '../../assets/images/dec10.jpg';
import NewsSidebar from './NewsSidebar';
import FeaturedNews from './FeaturedNews';
import getWindowSize from '../../utils/getScreenSize';
import { responsiveFontSizes } from '../../utils/getFontValue';

function fDate(date, option) {
  return format(new Date(date), option || 'dd MMM yyyy');
}

const Posts = [
  {
    title: 'Quantum Threats, Cybersecurity Upheaval',
    duration: '7 mins',
    coverImgSm: Jan14CoverImage,
    coverImg: Jan14CoverImage,
    description: '',
    author: {
      name: 'Noel Grover',
      picture: NoelAvatar,
    },
    createdAt: new Date('Jan 14, 2024'),
    internalLink: '/news/Jan1424',
  },
  {
    title: 'Quantum Alliance Sparks Cyber Alert',
    duration: '6 mins',
    coverImgSm: FaaShutdownSmallCoverImage,
    coverImg: FaaShutdownSmallCoverImage,
    description: '',
    author: {
      name: 'Tucker Saxon',
      picture: TuckerAvatar,
    },
    createdAt: new Date('Jan 7, 2024'),
    internalLink: '/news/Jan0724',
  },
  {
    title: 'Cyber Threats Rock Defense Sector',
    duration: '10 mins',
    coverImg: WhatIsCyberResilienceCoverImage,
    description: '',
    author: {
      name: 'Noel Grover',
      picture: NoelAvatar,
    },
    createdAt: new Date('Dec 10, 2023'),
    internalLink: '/news/Dec1023',
  },
  {
    title: 'Diamonds In Quantum Computing',
    duration: '10 mins',
    coverImg: Dec0223Cover,
    description: '',
    author: {
      name: 'Noel Grover',
      picture: NoelAvatar,
    },
    createdAt: new Date('Dec 02, 2023'),
    internalLink: '/news/Dec0223',
  },
  {
    title: 'Health Data Breach, Toyota Cyberattack, SSH Risks',
    duration: '8 mins',
    coverImg: Nov2023Cover,
    description: '',
    author: {
      name: 'Tucker Saxon',
      picture: TuckerAvatar,
    },
    createdAt: new Date('Nov 20, 2023'),
    internalLink: '/news/Nov2023',
  },
];

export function NewsPostItem({ post }) {
  const {
    title, duration, coverImg, coverImgSm,
    description, author, targetLink,
    useSmallImageInBlogCard, internalLink,
    createdAt,
  } = post;
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const navigate = useNavigate();

  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: 2,
        overflow: 'hidden',
        ':hover': {
          boxShadow: 20, // theme.shadows[20]
        },
      }}
      onClick={() => {
        if (internalLink) {
          navigate(internalLink);
        } else {
          window.open(targetLink, '_blank');
        }
      }}
    >
      <Image src={useSmallImageInBlogCard ? coverImgSm : coverImg} alt={title} ratio="1/1" />
      <Stack direction="row" spacing={3} sx={{ p: 3, height: '140px' }}>
        <Stack sx={{ textAlign: 'center' }}>
          <Typography variant="subtitle2" sx={{ color: '#054A91' }}>{fDate(createdAt, 'MMM')}</Typography>
          <Divider sx={{ mt: 1, mb: 0.5 }} />
          <Typography variant="h3" sx={{ color: '#054A91' }}>{fDate(createdAt, 'dd')}</Typography>
        </Stack>
        <Stack spacing={1} justifyContent="space-between" flexGrow={1}>
          <Typography
            variant="h6"
            sx={{
              ...responsiveFontSizes({
                xs: 26, sm: 26, md: 26, lg: 30,
              }),
              color: '#054A91',
            }}
          >
            {title}
          </Typography>

          <Typography
            variant="body2"
            persistent
          >
            {description}
          </Typography>
        </Stack>

      </Stack>
      <Stack
        direction="row"
        spacing={3}
        sx={{ px: 3, pb: 3, pt: 0 }}
      >
        <Stack spacing={1.5} direction="row" alignItems="center" sx={{ pt: 1.5 }}>
          <Avatar src={author.picture} sx={{ width: 40, height: 40 }} />
          <Stack>
            <Typography variant="body2" sx={{ color: '#054A91' }}>{author.name}</Typography>
            <Typography variant="caption" sx={{ color: '#054A91' }}>
              {duration}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

    </Paper>
  );
}

export default function NewsLanding() {
  return (
    <>
      <Box sx={{
        height: 'auto',
        // minHeight: { md: '100vh' },
        backgroundColor: '#e3e6e4',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      >
        <Container sx={{
          py: { xs: 3, md: 3, lg: 10 },
          px: 0,
        }}
        >
          {/* <Typography variant="h2">Featured Blog:</Typography> */}
          <FeaturedNews post={Posts[0]} />
        </Container>
      </Box>
      <Container
        sx={{
          mt: { xs: 4, md: 10 },
          pb: 4,
        }}
      >
        <Grid container columnSpacing={4} rowSpacing={4}>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: 'grid',
                rowGap: { xs: 4, md: 5 },
                columnGap: 4,
                pr: { xs: 0, lg: 1.5 },
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                },
              }}
            >
              {/* <BlogPostItem post={Posts[0]} /> */}
              <NewsPostItem post={Posts[1]} />
              <NewsPostItem post={Posts[2]} />
              <NewsPostItem post={Posts[3]} />
              <NewsPostItem post={Posts[4]} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <NewsSidebar />
          </Grid>
        </Grid>

      </Container>
    </>
  );
}
