/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
} from '@mui/material';
import WhatIsCyberResilienceCoverImage from '../../../assets/images/CoverImages/WhatIsCyberResilienceCoverImage.jpg';
import { responsiveFontSizes } from '../../../utils/getFontValue';

export default function WhatIsCyberResilience() {
  return (
    <>
      <Box sx={{
        backgroundImage: `url(${WhatIsCyberResilienceCoverImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100% 100%',
        marginTop: '64px',
      }}
      >
        <Container sx={{
          width: '100vw',
          height: '66vw',
          // height: {
          //   xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          // },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#EEF1EF',
        }}
        />
      </Box>
      <Box
        sx={{
          '@media (min-width: 600px)': {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 6 },
          pb: { xs: 6, md: 6, lg: 9 },
        }}
        >
          <Grid
            container
            spacing={3}
            justifyContent={{ md: 'center' }}
          >
            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 'bold',
                  color: '#054A91',
                  ...responsiveFontSizes({
                    xs: 34, sm: 34, md: 34, lg: 40,
                  }),
                }}
              >
                What is Cyber Resilience?
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Cyber Resilience Overview
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                Cyber resilience is a critical topic for organizations who spend years
                maturing their cyber security detection, response tools, and processes.
                Major investment in tackling arising challenges  inevitably evolves the
                conversation   into that which addresses how all the facets of cybersecurity need
                to work together to create a resilient organization from
                a cybersecurity perspective.
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>
                The best detection methods have no impact if the organization cannot recover the
                affected systems and continue business operations following a breach. Operating
                cybersecurity as a cohesive cross-functional program rather than a set of siloed
                areas can help build further resilience by streamlining the entire process and
                bringing the organization back to operations as quickly as possible following
                a major cybersecurity incident.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#054A91',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                Cyber Resilience Strategies/Best Practices
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Business Continuity/Disaster Recovery Exercises
              </Typography>

              <Typography>
                COVID-19 and the public/private institutional responses to it, brought to
                light previously undetected structural vulnerabilities to organizations
                using outdated models of the boundaries of their IT infrastructure.
                The rapid diaspora of the workforce into remote roles away from a geographically
                centralized IT model compounded the difficulty of implementing capabilities in
                business continuity and disaster recovery.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                A critical aspect of cyber and business resilience is
                performing realistic exercises, involving all interested parties,
                and promoting cross-functional collaboration between teams.
                By involving all relevant stakeholders, these drills can help
                teams operate more realistically and help the group understand
                and document dependencies between teams or identify areas where
                institutional knowledge lies with only one critical resource who
                can become a single point of failure during an emergency
                if they are unavailable.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                Operating and documenting realistic business continuity exercises
                is a crucial step in cyber resilience.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Assumed Breach
              </Typography>

              <Typography>
                Implementing proper  breach methodology can help organizations develop
                a well-rounded cybersecurity program.  As cyber-attacks become more
                sophisticated and frequent, it&apos;s nearly inevitable that an organization
                will experience a breach or incident, or will experience one in the future.
                By focusing organization resources on the identification and detection phase
                of cybersecurity, the equally important response and recovery
                stages can end up being neglected.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                Building robust prevention and detection methods alone will not completely
                insulate the organization. Assuming a breach or incident will occur helps
                sharpen the organization’s focus on recovery and response methods and makes
                the impending incident feel more real and inevitable. When incidents do occur,
                those that are tasked with triage would have seen this coming,
                and can operate with far more confidence and composure.”)
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#054A91',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                Challenges in Cyber Resilience
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Cross Functional Teams
              </Typography>

              <Typography>
                Bringing together teams across the cybersecurity, IT and business functions of
                the company can be challenging as buy-in is needed from multiple
                levels of authority.  Organizations are often structured in a siloed manner,
                allowing teams to get entrenched in their area of expertise and never pushing
                teams to understand organizational context or the
                responsibilities of other departments.  Achieving buy-in from teams across IT,
                cybersecurity, and the organization can prove challenging in a
                fractured organization. But the benefits are worth the effort when the goal to
                keep IT infrastructure secure and operating in support of the business is
                pushed forward by teams which are properly engaged in
                collaborating towards a shared goal.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{
                fontWeight: 'bold',
              }}
              >
                Shifting Threats
              </Typography>

              <Typography>
                The entire world of cybersecurity can be conceptualized as a large number of
                simultaneously occurring arms races between threat actors and the companies
                defending against them. The battle space is asymmetrical in nature as defenses
                can only be created against known threats, while threat actors have no
                obligation to publicly disclose new threat vectors and tools they create
                to one up the defense. As threats and tactics are shifting constantly,
                it can be nearly impossible for security teams to keep up.
                These challenges make cyber resilience difficult,
                as the target is continually moving.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography
                variant="h2"
                sx={{
                  color: '#054A91',
                  ...responsiveFontSizes({
                    xs: 28, sm: 28, md: 28, lg: 32,
                  }),
                }}
              >
                How is cyber resilience evolving?
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                Cyber resilience is part of an evolution of the
                maturity of cybersecurity programs, as organizations look to not
                only defend themselves but have a holistic understanding
                of recovery and resilience.  Weaving the various functions of
                the cybersecurity, IT, and business departments into a single cohesive
                unit will prove to be challenging, as the threat landscape is evolving rapidly.
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>
                Quantum computing brings further challenges, as encryption algorithms once accepted
                as industry standard can be cracked by quantum computing processes.
                As post-quantum technology becomes widely available, it will inevitably fall
                into the hands of threat actors who will attempt to use it to break encryption
                at its current standard.  Building quantum resilient technologies and processes
                will become an increasingly important goal for organizations to implement as the
                previous industry encryption standards will no longer be sufficient to
                defend against threat actors with access to quantum computing.
              </Typography>
            </Grid>

          </Grid>

        </Container>
      </Box>

    </>
  );
}
