/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Stack,
  Card,
  CardHeader,
  Link,
  Button,
  CardActions,
  Avatar,
} from '@mui/material';
import WAVES from 'vanta/dist/vanta.waves.min';
import locationIcon from '@iconify/icons-carbon/location';
import mobileIcon from '@iconify/icons-carbon/mobile';
import emailIcon from '@iconify/icons-carbon/email';
import { m } from 'framer-motion';
import Iconify from '../Iconify';
import TextIconLabel from '../TextIconLabel';
import getWindowSize from '../../utils/getScreenSize';

import WhatIsQuantaSafeCoverImage from '../../assets/images/CoverImages/QuantaSafeCoverImage.png';
import { responsiveFontSizes } from '../../utils/getFontValue';
import SecureLiteImage from '../../assets/images/SecureLite.png';
import planeImage from '../../assets/images/plane.png';

// TODO: Check if this is needed anymore3
import '../EnquantaAdvantage/EnquantaAdvantage.css';

// TODO: clean up this doo doo

const regularTextFontSizes = {
  xs: 16, sm: 18, md: 18, lg: 18,
};

function Intro({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      QuantaSafe Secure Lite, featuring the Core Command Line Interface (CLI),
      is a streamlined version of QuantaSafe designed for ease of use in command-line environments.
      This variant is particularly suitable for integration into customer workflows,
      especially in cases where the architecture differs from the Secure Vault or
      Secure Storage products.
    </Typography>
  );
}

function BodyParagraph1({ sx }) {
  return (
    <Typography sx={{
      marginBottom: '10px',
      ...responsiveFontSizes(regularTextFontSizes),
      ...sx,
    }}
    >
      The QuantaSafe CLI stands out as a minimalistic yet powerful tool,
      offering all the security advantages of QuantaSafe in a single, easy-to-use binary.
      This binary is capable of both packaging and reconstituting data efficiently.
      As a standalone application, it doesn&apos;t rely on network connectivity or a
      separate configuration application, enhancing its versatility and user-friendliness.
      It is important to note that the responsibility for proper handling of the Quantum
      Quantum Cryptex  Unit (QCU) and reconstituted data lies with the end user.
      The QuantaSafe CLI offers flexibility in data management, supporting configurations
      to use RAMFS or a removable drive, with the option to write directly to
      the hard disk also being viable.
    </Typography>

  );
}

export default function SecureLite() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const [vantaEffect, setVantaEffect] = useState(null);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(WAVES({
        el: myRef.current,
        color: 0x251742,
        shininess: 75.00,
        waveHeight: 16.00,
        waveSpeed: 1.00,
        zoom: 0.93,
      }));
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  let quantumIntelligenceDiagramHeight = '250px';
  if (windowSize.innerWidth >= 1200) {
    quantumIntelligenceDiagramHeight = '370px';
  } else if (windowSize.innerWidth >= 900) {
    quantumIntelligenceDiagramHeight = '320px';
  } else if (windowSize.innerWidth >= 600) {
    quantumIntelligenceDiagramHeight = '300px';
  }
  return (
    <>

      <Box
        ref={myRef}
        sx={{
          backgroundImage: `url(${WhatIsQuantaSafeCoverImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: '100% 100%',
          marginTop: '64px',

        }}
      >
        <Container sx={{
          width: '100vw',
          height: {
            xs: '300px', sm: '500px', md: '700px', lg: '100vh',
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#EEF1EF',
        }}
        >
          <Grid container columnSpacing={3}>
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
              <Typography
                variant="h1"
                sx={{
                  color: '#EEF1EF',
                  fontFamily: 'branding',
                  fontWeight: 'bold',
                  ...responsiveFontSizes({
                    xs: 40, sm: 70, md: 80, lg: 96,
                  }),
                }}
              >
                What is Secure Lite?
              </Typography>

            </Grid>

          </Grid>
        </Container>
      </Box>

      <Box sx={{
        height: 'auto',
        // minHeight: { md: '100vh' },
        '@media (min-width: 600px)': {
          paddingLeft: '24px',
          paddingRight: '24px',
        },
        backgroundColor: '#EEF1EF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      >
        <Container sx={{
          pt: { xs: 3, md: 3, lg: 9 },
          pb: { xs: 3, md: 3, lg: 9 },
        }}
        >
          <Grid
            container
            columnSpacing={{
              xs: 0, sm: 0, md: 0, lg: 6,
            }}
            rowSpacing={{
              xs: 0, sm: 0, md: 0, lg: 3,
            }}
            sx={{ justifyContent: 'center' }}
          >
            <Grid item md={8}>
              <Grid
                container
                spacing={{
                  xs: 0, sm: 0, md: 0, lg: 3,
                }}
              >
                <Grid item md={2}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      py: 2,
                      my: { xs: 0, md: 4, lg: 2 },
                    }}
                  >
                    <img src={SecureLiteImage} alt="QuantaSafe" style={{ height: '100px' }} />
                  </Box>
                </Grid>
                <Grid item md={10}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: '5px',
                      ...responsiveFontSizes({
                        xs: 22, sm: 28, md: 28, lg: 36,
                      }),
                      color: '#054A91',
                      fontWeight: 'bold',
                    }}
                  >
                    Summary Header
                  </Typography>
                  <Intro />
                </Grid>

                <Grid item md={23}>
                  <BodyParagraph1 />
                </Grid>
                <Grid item md={12}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: '5px',
                      ...responsiveFontSizes({
                        xs: 22, sm: 28, md: 28, lg: 36,
                      }),
                      color: '#054A91',
                      fontWeight: 'bold',
                    }}
                  >
                    Features
                  </Typography>
                  <Intro />
                </Grid>
              </Grid>

            </Grid>
            <Grid item md={4}>
              <Card sx={{ background: '#F7F7F7' }}>
                {/* <CardHeader title="Have more questions?" /> */}
                <Stack
                  spacing={1.5}
                  sx={{ p: 3 }}
                  component={m.div}
                  variants={{ hover: { opacity: 0.8 } }}
                >
                  <Typography variant="h3" sx={{ color: '#054A91', mb: 0.5 }}>
                    Have more questions?
                  </Typography>
                  <Stack spacing={0.5} sx={{ width: '100%' }}>
                    <TextIconLabel
                      icon={<Iconify icon={locationIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
                      value={(
                        <>
                          Main Address:
                        </>
                      )}
                      sx={{ typography: 'subtitle2', mb: 0.5 }}
                    />
                    <Typography variant="body2">6385 Old Shady Oak Rd.</Typography>
                    <Typography variant="body2">Suite 250</Typography>
                    <Typography variant="body2">Eden Prairie, MN 55344</Typography>
                  </Stack>

                  <Stack spacing={0.5}>
                    <TextIconLabel
                      icon={<Iconify icon={mobileIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
                      value="Phone:"
                      sx={{ typography: 'subtitle2', mb: 0.5 }}
                    />
                    <Typography variant="body2">
                      <Link
                        sx={{ lineHeight: 0 }}
                        rel="noopener noreferrer"
                        href="tel:612-423-9015"
                      >
                        612-423-9015
                      </Link>
                    </Typography>
                  </Stack>

                  <Stack spacing={0.5}>
                    <TextIconLabel
                      icon={<Iconify icon={emailIcon} sx={{ mr: 1, width: 24, height: 24 }} />}
                      value="Email:"
                      sx={{ typography: 'subtitle2', mb: 0.5 }}
                    />
                    <Typography variant="body2">
                      <Link
                        sx={{ lineHeight: 0 }}
                        rel="noopener noreferrer"
                        href="mailto:sales@enquanta.io"
                      >
                        sales@enquanta.io
                      </Link>
                    </Typography>
                  </Stack>
                  <Button variant="contained" sx={{ width: '50%' }}>
                    Contact Us
                  </Button>
                </Stack>

              </Card>
            </Grid>

            <Grid item md={12}>
              <Grid
                container
                spacing={{
                  xs: 0, sm: 0, md: 0, lg: 3,
                }}
              >
                <Grid item md={12}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: '5px',
                      ...responsiveFontSizes({
                        xs: 22, sm: 28, md: 28, lg: 36,
                      }),
                      color: '#054A91',
                      fontWeight: 'bold',
                    }}
                  >
                    Use Cases
                  </Typography>
                </Grid>

                <Grid item md={4}>
                  <Avatar
                    variant="rounded"
                    alt="plane"
                    src={planeImage}
                    sx={{ width: '100%', height: '200px' }}
                  />
                </Grid>
                <Grid item md={8}>
                  <Intro />

                </Grid>
                <Grid item md={8}>
                  <Intro />

                </Grid>
                <Grid item md={4}>
                  <Avatar
                    variant="rounded"
                    alt="plane"
                    src={planeImage}
                    sx={{ width: '100%', height: '200px' }}
                  />
                </Grid>

              </Grid>

            </Grid>
          </Grid>

        </Container>
      </Box>
    </>
  );
}
