/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
// icons
import menuIcon from '@iconify/icons-carbon/menu';
import chevronIcon from '@iconify/icons-carbon/chevron-down';
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Stack,
  Button,
  Drawer,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Typography,
  Collapse,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/Logo.png';
import HexaLogo from '../../assets/images/HexaLogo.png';
import HexaLogoAnimated from '../../assets/images/HexaLogoAnimated.gif';

// components
import Iconify from '../Iconify';

export const StyledItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({
  active, depth, theme,
}) => {
  const subItem = depth !== 1;

  const deepSubItem = depth > 2;

  const activeStyles = {
    root: {
      color:
        theme.palette.primary.light,
      backgroundColor: alpha(theme.palette.primary.light, 0.08),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.light, 0.16),
      },
    },
    sub: {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  };

  return {
    // Root item
    padding: '6px 0 6px 6px',
    marginBottom: 1,
    borderRadius: 8,
    minHeight: 44,
    color: theme.palette.text.secondary,

    // Active root item
    ...(active && {
      ...activeStyles.root,
    }),

    // Sub item
    ...(subItem && {
      minHeight: 36,
      // Active sub item
      ...(active && {
        ...activeStyles.sub,
      }),
    }),

    // Deep sub item
    ...(deepSubItem && {
      paddingLeft: theme.spacing(depth),
    }),
  };
});

export const StyledIcon = styled(ListItemIcon)(({ size }) => ({
  width: size,
  height: size,
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledDotIcon = styled('span')(({ active, theme }) => ({
  width: 4,
  height: 4,
  borderRadius: '50%',
  backgroundColor: theme.palette.text.disabled,
  transition: theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.shorter,
  }),
  ...(active && {
    transform: 'scale(2)',
    backgroundColor: '#7F5CAD',
  }),
}));

const DRAWER_WIDTH = 280;

export default function NavMobile({ navConfig, sx }) {
  const navigate = useNavigate();
  const [isProductsCollapseOpen, setIsProductsCollapseOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // useEffect(() => {
  //   if (drawerOpen) {
  //     handleDrawerClose();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <Iconify icon={menuIcon} onClick={handleDrawerOpen} sx={{ color: '#000000' }} fontSize="24px" />

      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: { width: DRAWER_WIDTH },
        }}
      >
        <Box sx={{ overflowX: 'auto' }}>

          <Box sx={{ px: 2.5, py: 3, lineHeight: 0 }}>
            <Button
              variant="text"
              onClick={() => {
                handleDrawerClose();
                navigate('/');
              }}
            >
              <img src={HexaLogoAnimated} style={{ width: '24px', paddingTop: '2px', marginRight: '3px' }} alt="Hexagon logo" />
              <img src={Logo} style={{ width: '120px', paddingTop: '5px', marginLeft: '5px' }} alt="Enquanta logo" />
            </Button>
          </Box>

          <Stack spacing={2} sx={{ p: 2.5, pb: 5 }}>
            <Button
              variant="contained"
              href=""
              target="_blank"
              rel="noopener"
              sx={{ backgroundColor: '#054A91', fontSize: '1.1rem', width: '60%' }}
              onClick={() => {
                handleDrawerClose();
                navigate('contactUs');
              }}
            >
              Contact Us
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}
