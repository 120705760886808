import React from 'react';
import {
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { responsiveFontSizes } from '../../utils/getFontValue';
import SecureVaultImage from '../../assets/images/SecureVaultIntro.png';

function SecureVaultIntro() {
  return (
    <Grid
      container
      spacing={4}
      sx={{
        backgroundColor: '#DEDBE9',
        mb: 4,
      }}
    >
      <Grid item container xs={12} md={6} alignItems="center" justifyContent="end">
        <Box
          data-aos="fade-right"
          sx={{
            mr: 8,
          }}
        >
          <Box
            marginBottom={2}
          >
            <Typography
              variant="h1"
              sx={{
                ...responsiveFontSizes({
                  xs: 34, sm: 34, md: 34, lg: 80,
                }),
                color: '#422A76',
              }}
            >
              QuantaSafe
              <br />
              Secure Vault
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography variant="h6" component="p" color="text.secondary">
              Data Theft Resilience
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="start"
        xs={12}
        md={6}
      >
        <Box
          component="img"
          loading="lazy"
          data-aos="fade-left"
          height={1}
          width={1}
          src={SecureVaultImage}
          alt="..."
          maxWidth={600}
          maxHeight={500}
          sx={{
            objectFit: 'contain',
          }}
        />
      </Grid>
    </Grid>
  );
}

export default SecureVaultIntro;
